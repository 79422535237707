import { BehaviorSubject } from 'rxjs';
import { UsersnapConfig, UsersnapUserData } from '../model';
import { UsersnapUserDataLoader } from '../providers/usersnap-userdata.provider';

/**
 * This is the usersnap initializer, which should only be called once on app initialization
 *
 * @param config Usersnap Config
 * @param document Document instance
 * @param userdataLoader Usersnap User Data Loader to provide Usersnap User Data for initialization
 */
export function usersnapInitializer(config: UsersnapConfig, document: Document, userdataLoader: UsersnapUserDataLoader) {
  return () => {
    // if config are missing, log the error and return
    if (!config?.userApiKey) {
      console.error('Empty user api key for Usersnap. Please provide one when importing the UsersnapModule.forRoot()');
      return;
    }

    // if document is missing, log the error and return
    if (!document || !document.defaultView) {
      console.error('Please make sure the environment is a browser. Was not possible to access `document` instance');
      return;
    }

    let initialized = false;
    const userData$ = new BehaviorSubject<UsersnapUserData | null>(null);
    userdataLoader.getUserdata().subscribe((userData) => {
      userData$.next(userData);
      if (!initialized) {
        init();
      }
    });

    function init() {
      //Check if usersnap should be enabled at all
      userdataLoader.isEnabled().subscribe((result: boolean) => {
        if (result) {
          // Create the usersnap script url
          const url = `https://widget.usersnap.com/global/load/${config.globalApiKey}?onload=onUsersnapCXLoad`;

          // Create the usersnap script tag and append it to the head
          const head = document.querySelector('head');
          const script = document.createElement('script');
          script.defer = false;
          script.type = 'text/javascript';
          script.src = url;
          head?.appendChild(script);

          initialized = true;
        }
      });
    }

    // callback function when script was downloaded
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).onUsersnapCXLoad = (api: any) => {
      // get the user data from the user data loader and intialze usersnap
      userData$.subscribe((userData) => {
        // destroys the api before initializing a new one
        api.destroy().then(() => {
          // initialize api
          api
            .init(
              userData?.id || userData?.email
                ? {
                    user: {
                      userId: userData.id ?? '',
                      email: userData.email ?? '',
                    },
                  }
                : {},
            )
            .then(() => {
              // show usersnap if user is allowed to use it
              if (userData && userData.allowed !== false) {
                api.show(config.userApiKey);
              } else if (userData && userData.allowed === false) {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                api.hide && api.hide(config.userApiKey);
              }
            });
        });
      });
    };
  };
}
