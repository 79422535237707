import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'shared-table-icon',
    templateUrl: './table-icon.component.html',
    styleUrls: ['./table-icon.component.scss'],
    standalone: false
})
export class TableIconComponent implements OnInit {
  @Input() url?: string;
  @Input() iconImageFile?: string;
  @Input() alt?: string;

  @Input() public urlFn: (imageFile: string) => string = (): string => '';

  ngOnInit(): void {
    if (!this.url) {
      this.url = this.urlFn(this.iconImageFile);
    }
  }
}
