import { Clipboard } from '@angular/cdk/clipboard';
import { OverlayRef } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'shared-permission-overlay',
    templateUrl: 'permission-overlay.component.html',
    styleUrls: ['permission-overlay.component.scss'],
    standalone: false
})
export class PermissionOverlayComponent implements AfterViewInit, OnInit, OnDestroy {
  securedElements: Element[];

  protected overlayRef: OverlayRef;
  intersectionObserver: IntersectionObserver;

  constructor(
    private clipboard: Clipboard,
    private snackbar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.document.addEventListener('keyup', this.handleKeyboardEvent, true);
    this.document.addEventListener('keydown', this.handleKeyboardEvent, true);
  }

  ngOnDestroy() {
    this.overlayRef = null;
    this.document.removeEventListener('keyup', this.handleKeyboardEvent, true);
    this.document.removeEventListener('keydown', this.handleKeyboardEvent, true);

    this.intersectionObserver?.disconnect();
  }

  setOverlayRef(overlayRef: OverlayRef) {
    this.overlayRef = overlayRef;
  }

  private handleKeyboardEvent = (event: KeyboardEvent) => {
    if (event.code === 'Escape' && this.overlayRef) {
      this.overlayRef.dispose();
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
    return;
  };

  ngAfterViewInit(): void {
    setTimeout(() => {
      const isDialog = document.querySelector('spx-dialog');
      const isMenu = document.querySelector('.mat-menu-panel');
      let queryString = '[app-secured]';

      if (isDialog) {
        queryString = 'spx-dialog ' + queryString;
      }

      if (isMenu) {
        queryString = '.mat-menu-panel ' + queryString;
      }

      this.intersectionObserver = new IntersectionObserver(
        (entries) => {
          this.securedElements = entries.filter((e) => e.isIntersecting).map((e) => e.target);
        },
        {
          threshold: 0.5,
        },
      );

      document.querySelectorAll<HTMLElement>(queryString).forEach((element) => {
        this.intersectionObserver.observe(element);
      });

      document.querySelector<HTMLElement>('.cdk-overlay-pane').click();
    });
  }

  getSecureActionKey(element: Element) {
    return element.getAttribute('app-secured');
  }

  copyKeyToClipBoard(element: Element) {
    const key = this.getSecureActionKey(element);
    this.clipboard.copy(key);
    this.overlayRef.dispose();

    this.snackbar.open('Action key copied to clipboard', '', {
      duration: 2000,
    });
  }

  getSecureActionPosition(element: Element) {
    const rect = element.getBoundingClientRect();
    const topOffset = rect.height < 24 ? 24 - rect.height / 2 - 12 + 1 : 1;
    const leftOffset = rect.width < 24 ? 24 - rect.width / 2 - 12 + 1 : 1;

    return {
      position: 'absolute',
      top: rect.top - topOffset + 'px',
      left: rect.left - leftOffset + 'px',
      width: rect.width + 2 + 'px',
      height: rect.height + 2 + 'px',
      background: 'grey',
      opacity: 0.8,
    };
  }
}
