import { Component, Input } from '@angular/core';
import { NotificationService, NotificationType } from '@ird/ng-base';

@Component({
    selector: 'shared-copy-content',
    templateUrl: './copy-content-button.component.html',
    styleUrls: ['./copy-content-button.component.scss'],
    standalone: false
})
export class CopyContentButtonComponent {
  @Input() textToCopy: string;

  constructor(private notificationService: NotificationService) {}

  onClipboardCopy(success: boolean) {
    if (success) {
      this.notificationService.showNotification({
        message: 'DEVICE_SERIAL_NR_TEXT_COPIED',
        type: NotificationType.Primary,
      });
    }
  }
}
