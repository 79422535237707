// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-flex;
}

mat-form-field mat-hint {
  visibility: hidden;
}

mat-form-field:focus-within mat-hint {
  visibility: visible;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/password-input/password-input.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[":host {\n  display: inline-flex;\n}\n\nmat-form-field mat-hint {\n  visibility: hidden;\n}\n\nmat-form-field:focus-within mat-hint {\n  visibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
