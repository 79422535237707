// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .export-csv-container {
  display: flex;
  padding: 0 16px;
  justify-items: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/export-csv-snackbar/export-csv-snackbar.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,eAAA;EACA,qBAAA;EACA,mBAAA;AAAJ","sourcesContent":[":host {\r\n  .export-csv-container {\r\n    display: flex;\r\n    padding: 0 16px;\r\n    justify-items: center;\r\n    align-items: center;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
