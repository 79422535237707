import { Pageable, Sort } from '../pageable';
import { SearchableSortableHttpParam } from './searchable-sortable-http-param';
import { CrudGetAllDto } from '../crud';

export const createSearchableSortableHttpParam = (query: CrudGetAllDto) => {
  const [active, sortDirection] = query.sort?.split(',', 2) ?? [];

  let sortOrders;
  if (query.sort) {
    sortOrders = [
      {
        property: active,
        direction: sortDirection?.toUpperCase(),
      },
    ];
  }
  const sort = new Sort(sortOrders);
  const pageable = new Pageable(query.size, query.page, sort);

  return validateSearchableSortableHttpParam({ query: query.searchQuery, pageable: pageable });
};

export const validateSearchableSortableHttpParam = (
  searchableSortableHttpParam: SearchableSortableHttpParam,
): SearchableSortableHttpParam => {
  if (!searchableSortableHttpParam.query || searchableSortableHttpParam.query === 'undefined') {
    searchableSortableHttpParam.query = '';
  }

  const validatedSortOrders: { property: string; direction: string }[] = [];
  if (searchableSortableHttpParam?.pageable?.sort?.sortOrders) {
    Object.values(searchableSortableHttpParam.pageable?.sort.sortOrders).forEach((order: { property: string; direction: string }) => {
      if (!order.direction || !order.property) {
        return;
      }

      if (order.direction !== 'undefined' && order.property !== 'undefined') {
        validatedSortOrders.push(order);
      }
    });
    searchableSortableHttpParam.pageable.sort.sortOrders = validatedSortOrders;
  }
  return searchableSortableHttpParam;
};
