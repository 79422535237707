import { DOCUMENT } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider, inject, provideAppInitializer } from '@angular/core';
import { freescoutInitializer } from './freescout.initializer';
import { FreescoutConfigFakeLoader, FreescoutConfigLoader } from './providers/freescout-config.provder';
import { FreescoutService } from './services/freescout.service';

@NgModule()
export class FreescoutModule {
  static forRoot(configLoader: Provider): ModuleWithProviders<FreescoutModule> {
    return {
      ngModule: FreescoutModule,
      providers: [
        configLoader || { provide: FreescoutConfigLoader, useClass: FreescoutConfigFakeLoader },
        provideAppInitializer(() => {
        const initializerFn = (freescoutInitializer)(inject(DOCUMENT), inject(FreescoutConfigLoader));
        return initializerFn();
      }),
        FreescoutService,
      ],
    };
  }
}
