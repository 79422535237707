// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .copy-value-icon {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/copy-content-button/copy-content-button.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ","sourcesContent":[":host {\n  .copy-value-icon {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
