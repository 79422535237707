// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  height: inherit;
  justify-content: center;
}

img {
  width: 100%;
  height: 100%;
  position: absolute;
}

.fit-image {
  object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./apps/scs-service-point/src/app/shared/image/image.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[":host {\n  display: flex;\n  height: inherit;\n  justify-content: center;\n}\n\nimg {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n}\n\n.fit-image {\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
