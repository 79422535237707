// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host-context(.no-padding) .content {
  padding: 0;
}

.content {
  overflow: hidden;
}

.header {
  display: flex;
  width: 100%;
}

.titlebar {
  align-items: baseline;
  padding: 16px;
}

.back-button {
  cursor: pointer;
  text-align: center;
  width: 24px;
}
.back-button spx-icon {
  height: 24px;
  padding: 0;
  width: 24px;
}

.page-title {
  margin-left: 8px;
}

.back-icon {
  padding-left: 16px;
}`, "",{"version":3,"sources":["webpack://./apps/scs-service-point/src/app/layout/components/layout/layout.component.scss"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,aAAA;EACA,WAAA;AADF;;AAIA;EACE,qBAAA;EACA,aAAA;AADF;;AAIA;EACE,eAAA;EACA,kBAAA;EACA,WAAA;AADF;AAGE;EACE,YAAA;EACA,UAAA;EACA,WAAA;AADJ;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,kBAAA;AAFF","sourcesContent":[":host-context(.no-padding) {\n  .content {\n    padding: 0;\n  }\n}\n\n.content {\n  overflow: hidden;\n}\n\n.header {\n  display: flex;\n  width: 100%;\n}\n\n.titlebar {\n  align-items: baseline;\n  padding: 16px;\n}\n\n.back-button {\n  cursor: pointer;\n  text-align: center;\n  width: 24px;\n\n  spx-icon {\n    height: 24px;\n    padding: 0;\n    width: 24px;\n  }\n}\n\n.page-title {\n  margin-left: 8px;\n}\n\n.back-icon {\n  padding-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
