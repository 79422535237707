import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SpxObservableDataSource } from '@ird/ng-base';
import { IrdClassMetadata, IrdProperty, PAGE_SIZE_OPTIONS } from '@ird/shared-base';
import { GenericAdminService } from '../../services';
import { GenericAdminOptions } from '../../models/generic-admin-options.model';

@Component({
    selector: 'shared-generic-table',
    templateUrl: './generic-table.component.html',
    styleUrls: ['./generic-table.component.scss'],
    standalone: false
})
export class GenericTableComponent<Dto> implements OnInit {
  @Input() isLoading: boolean;
  @Input() dataSource: SpxObservableDataSource<any>;
  @Input() metadata: IrdClassMetadata<Dto>;
  @Input() options: GenericAdminOptions;
  @Input() withActions: boolean = true;

  @Output() updateDto = new EventEmitter<Dto>();
  @Output() deleteDto = new EventEmitter<Dto>();

  visibleProperties: IrdProperty<any, any>[] = [];

  protected readonly PAGE_SIZE_OPTIONS: number[] = PAGE_SIZE_OPTIONS;

  constructor(private genericAdminService: GenericAdminService<Dto>) {}

  ngOnInit(): void {
    this.visibleProperties = this.genericAdminService.extractVisibleProperties(this.metadata);
  }

  openUpdateDialog(dto: Dto): void {
    this.updateDto.emit(dto);
  }

  openDeleteDialog(dto: Dto): void {
    this.deleteDto.emit(dto);
  }

  formatJson(dto: Dto, name: string): string {
    return this.genericAdminService.formatJson(dto[name]);
  }
}
