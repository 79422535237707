// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar {
  color: var(--app-color-contrast);
  background-color: var(--app-color);
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/sidenav/components/topbar/topbar.component.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,kCAAA;AACF","sourcesContent":[".topbar {\n  color: var(--app-color-contrast);\n  background-color: var(--app-color);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
