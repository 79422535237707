// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spx-menu-item {
  width: 100%;
  padding: 0;
  cursor: pointer !important;
}
.spx-menu-item.active {
  border-radius: 6px 6px 0 0;
}
.spx-menu-item.spx-menu-item {
  padding-left: 14px;
}
.spx-menu-item.spx-menu-item--child {
  padding-left: 24px;
}
.spx-menu-item.spx-menu-item--child:not(.active) {
  padding-left: 30px;
}
.spx-menu-item:not(.spx-menu-item--parent).active, .spx-menu-item:not(.spx-menu-item--expanded).active {
  border-radius: 6px;
}
.spx-menu-item__content spx-icon {
  padding-right: 16px;
}
.spx-menu-item__content * {
  z-index: 10;
}
.spx-menu-item__expand-container {
  width: 100%;
}

.child-items {
  height: 0;
  overflow: hidden;
  position: relative;
}
.child-items--expanded {
  height: auto;
  padding: 8px;
}
.child-items.active {
  border-radius: 0 0 6px 6px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/menu/components/menu-item/menu-item.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,UAAA;EACA,0BAAA;AACF;AACE;EACE,0BAAA;AACJ;AAEE;EACE,kBAAA;AAAJ;AAGE;EACE,kBAAA;AADJ;AAIE;EACE,kBAAA;AAFJ;AAKE;EAEE,kBAAA;AAJJ;AAOE;EACE,mBAAA;AALJ;AAQE;EACE,WAAA;AANJ;AASE;EACE,WAAA;AAPJ;;AAWA;EACE,SAAA;EACA,gBAAA;EACA,kBAAA;AARF;AAUE;EACE,YAAA;EACA,YAAA;AARJ;AAWE;EACE,0BAAA;AATJ","sourcesContent":[".spx-menu-item {\n  width: 100%;\n  padding: 0;\n  cursor: pointer !important;\n\n  &.active {\n    border-radius: 6px 6px 0 0;\n  }\n\n  &.spx-menu-item {\n    padding-left: 14px;\n  }\n\n  &.spx-menu-item--child {\n    padding-left: 24px;\n  }\n\n  &.spx-menu-item--child:not(.active) {\n    padding-left: 30px;\n  }\n\n  &:not(.spx-menu-item--parent).active,\n  &:not(.spx-menu-item--expanded).active {\n    border-radius: 6px;\n  }\n\n  &__content spx-icon {\n    padding-right: 16px;\n  }\n\n  &__content * {\n    z-index: 10;\n  }\n\n  &__expand-container {\n    width: 100%;\n  }\n}\n\n.child-items {\n  height: 0;\n  overflow: hidden;\n  position: relative;\n\n  &--expanded {\n    height: auto;\n    padding: 8px;\n  }\n\n  &.active {\n    border-radius: 0 0 6px 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
