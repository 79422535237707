// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host shared-icon-picker {
  height: 30%;
  margin-top: 1rem;
}
:host mat-form-field {
  width: 100%;
  margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/generic-edit-dialog/generic-edit-dialog.component.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,gBAAA;AAAJ;AAGE;EACE,WAAA;EACA,qBAAA;AADJ","sourcesContent":[":host {\n  shared-icon-picker {\n    height: 30%;\n    margin-top: 1rem;\n  }\n\n  mat-form-field {\n    width: 100%;\n    margin-bottom: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
