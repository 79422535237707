import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: '[spxChipListExpand]',
    standalone: false
})
export class ChipListExpandDirective {
  @HostBinding('class.expand') isExpanded = false;

  @HostListener('click')
  onClick() {
    this.isExpanded = !this.isExpanded;
  }
}
