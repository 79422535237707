// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-card-content {
  margin-top: 8px;
}

.image-container {
  height: 56px;
  margin-right: 24px;
  width: 72px;
}

/* stylelint-disable-next-line selector-pseudo-element-no-unknown */
.no-image ::ng-deep svg {
  height: 56px;
  margin-right: 24px;
  width: 72px;
}

.url-file {
  filter: grayscale(100%);
  opacity: 0.2;
}

.select {
  margin-right: 16px;
}

.image-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 40%;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/icon-picker/icon-picker.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;EACA,WAAA;AACF;;AAEA,mEAAA;AACA;EACE,YAAA;EACA,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,uBAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;AACF","sourcesContent":["mat-card-content {\n  margin-top: 8px;\n}\n\n.image-container {\n  height: 56px;\n  margin-right: 24px;\n  width: 72px;\n}\n\n/* stylelint-disable-next-line selector-pseudo-element-no-unknown */\n.no-image ::ng-deep svg {\n  height: 56px;\n  margin-right: 24px;\n  width: 72px;\n}\n\n.url-file {\n  filter: grayscale(100%);\n  opacity: 0.2;\n}\n\n.select {\n  margin-right: 16px;\n}\n\n.image-name {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  width: 40%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
