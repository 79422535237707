import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatCard, MatCardImage } from '@angular/material/card';
import { Color } from '../../model/color';

@Component({
    selector: 'spx-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    standalone: false
})
export class CardComponent implements OnInit, AfterViewInit {
  // Defines the aspect ration of card and card image, e.g. 300px card width leads to 200 px card image height (300 px / 1.5)
  static CARD_IMAGE_ASPECT_RATIO = 1.5;

  /**
   * Card image url
   */
  @Input() imageUrl!: string;

  /**
   * Theme color of
   */
  @Input() statusColor!: Color;

  /**
   * if a header content was provided or not
   */
  public headerContentAvailable = false;

  /**
   * if a action content was provided or not
   */
  public actionContentAvailable = false;

  // Elements to set card sizes
  @ViewChild(MatCard, { read: ElementRef, static: true }) cardElement!: ElementRef;
  @ViewChild(MatCardImage, { read: ElementRef }) imageElement!: ElementRef;
  @ViewChild('headerContent', { read: ElementRef, static: true }) headerContent!: ElementRef;
  @ViewChild('actionContent', { read: ElementRef, static: true }) actionContent!: ElementRef;

  constructor(private renderer: Renderer2) {}

  public ngOnInit(): void {
    this.headerContentAvailable = !!this.headerContent.nativeElement.innerHTML;
    this.actionContentAvailable = !!this.actionContent.nativeElement.innerHTML;
  }

  public ngAfterViewInit(): void {
    if (this.imageElement) {
      // set card width
      this.renderer.setStyle(this.cardElement.nativeElement, 'max-width', '100%');
      // set card image height based on card width
      this.renderer.setStyle(
        this.imageElement.nativeElement,
        'max-height',
        `${this.cardElement.nativeElement.offsetWidth / CardComponent.CARD_IMAGE_ASPECT_RATIO}px`
      );
      this.renderer.setStyle(
        this.imageElement.nativeElement,
        'min-height',
        `${this.cardElement.nativeElement.offsetWidth / CardComponent.CARD_IMAGE_ASPECT_RATIO}px`
      );
    }
  }
}
