// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  background-color: var(--warn-100);
  border-radius: 4px;
  color: var(--disabled-color);
  display: flex;
  padding: 16px;
}

.spx-body2 {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/warning-panel/warning-panel.component.scss"],"names":[],"mappings":"AAAA;EACE,iCAAA;EACA,kBAAA;EACA,4BAAA;EACA,aAAA;EACA,aAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[":host {\n  background-color: var(--warn-100);\n  border-radius: 4px;\n  color: var(--disabled-color);\n  display: flex;\n  padding: 16px;\n}\n\n.spx-body2 {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
