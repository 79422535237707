import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { ReplaySubject } from 'rxjs';
import { MaxRange, TimeRangeType, TimeRangeTypeEvent } from '../../models';
import DurationConstructor = moment.unitOfTime.DurationConstructor;
import Diff = moment.unitOfTime.Diff;

@Component({
    selector: 'spx-period-option-list',
    templateUrl: './period-option-list.component.html',
    styleUrls: ['./period-option-list.component.scss'],
    standalone: false
})
export class PeriodOptionListComponent implements OnInit, OnChanges {
  @Input() focusedPeriod?: TimeRangeType;
  @Input() defaultQuantity?: number;
  @Input() customLabelTrigger?: ReplaySubject<void>;
  @Input() configuredOptions?: Array<TimeRangeType>;
  @Input() maxRange?: MaxRange;
  @Input() minDate?: Moment;
  @Input() maxDate?: Moment;

  @Output() timeRangeChange: EventEmitter<TimeRangeTypeEvent> = new EventEmitter<TimeRangeTypeEvent>();

  public options: Array<{ label: string; disabled: boolean }> = [];
  public formGroup = new FormGroup({});

  ngOnInit() {
    this.options = (this.configuredOptions ?? Object.values(TimeRangeType)).map((label) => ({ label, disabled: false }));

    this.formUpdate('addControl');

    this.formGroup.valueChanges.subscribe(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (this.focusedPeriod && this.focusedPeriod !== 'custom' && !(this.formGroup.controls as any)[this.focusedPeriod]?.errors) {
        this.setTimeRange(this.focusedPeriod);
      }
    });

    this.customLabelTrigger?.subscribe(() => (this.focusedPeriod = TimeRangeType.CUSTOM));
  }

  ngOnChanges() {
    this.formUpdate('setControl');
  }

  public setTimeRange(period?: string, event?: Event, apply = false): void {
    event?.stopPropagation();

    if (period && (this.formGroup.get(period)?.valid || !period.endsWith('s'))) {
      this.focusedPeriod = period as TimeRangeType;
      const quantity = period.endsWith('s') ? this.formGroup.get(period)?.value : undefined;
      const range = this.getTimeRange(period as TimeRangeType, quantity);
      if (range && (quantity || !period.endsWith('s'))) {
        this.timeRangeChange.next({ apply, range, type: period as TimeRangeType, quantity });
      }
    }
  }

  public getTimeRange(type: TimeRangeType, amount?: number): DateRange<Moment> | undefined {
    let range: DateRange<Moment> | undefined;

    switch (type) {
      case TimeRangeType.TODAY:
        range = new DateRange<Moment>(moment().startOf('day'), moment().endOf('day'));
        break;
      case TimeRangeType.YESTERDAY:
        range = new DateRange<Moment>(moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day'));
        break;
      case TimeRangeType.CUSTOM:
        break;
      case TimeRangeType.YEAR:
        range = new DateRange<Moment>(moment().startOf('year'), moment());
        break;
      default:
        range = new DateRange<Moment>(moment().subtract(amount ?? 1, type as DurationConstructor), moment());
    }

    return range;
  }

  private formUpdate(method: 'addControl' | 'setControl'): void {
    this.options.forEach((option) => {
      const validators = [Validators.min(1), Validators.required];
      let disabled = false;
      if (this.maxDate?.isBefore(moment()) && option.label !== 'custom') {
        disabled = true;
        option.disabled = disabled;
      } else if (this.maxRange || this.minDate) {
        const dateRange = this.maxRange && moment().subtract(this.maxRange?.quantity, this.maxRange?.type);
        const nearestMin = !this.minDate || dateRange?.isAfter(this.minDate) ? dateRange : this.minDate;
        const difference = moment().diff(nearestMin, option.label as Diff);
        validators.push(Validators.max(difference));
        disabled = difference <= 1;
        option.disabled = disabled;
      }
      if (option.label.endsWith('s')) {
        let value;

        if (method === 'setControl') {
          value = this.formGroup.get(option.label)?.value;
        } else {
          value = option.label === this.focusedPeriod ? this.defaultQuantity ?? 1 : 1;
        }

        this.formGroup[method](option.label, new FormControl({ value, disabled }, validators));
      }
    });
  }
}
