// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  pointer-events: unset;
  white-space: nowrap;
}

.spx-button {
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  min-width: 24px;
  padding: 0 12px;
  pointer-events: auto;
  text-transform: uppercase;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/base/button/button.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,eAAA;EACA,eAAA;EACA,oBAAA;EACA,yBAAA;EACA,WAAA;AACF","sourcesContent":[":host {\n  pointer-events: unset;\n  white-space: nowrap;\n}\n\n.spx-button {\n  align-items: center;\n  display: flex;\n  height: 30px;\n  justify-content: center;\n  min-width: 24px;\n  padding: 0 12px;\n  pointer-events: auto;\n  text-transform: uppercase;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
