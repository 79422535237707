import { PagedData } from '../paged-data';
import { Omitter, genericMap } from './generic-map';

export type DaoType = Record<string, any>;
export type DtoType = Record<string, any>;

export interface SyncDtoMapper<Dao extends DaoType, Dto extends DtoType> {
  fromPagedDao(pagedData: PagedData<Dao>, toAdd: Omitter<Dao, Dto>): PagedData<Dto>;
  fromDao(dao: Dao, toAdd: Omitter<Dao, Dto>): Dto;
  fromDao(dao: Dao | null, toAdd: Omitter<Dao, Dto>): Dto | null;
  toDao(dto: Dto, toAdd: Omitter<Dto, Dao>): Dao;
  toDao(dto: Dto | null, toAdd: Omitter<Dto, Dao>): Dao | null;
}

export class DefaultSyncDtoMapper<Dao extends DaoType, Dto extends DtoType> implements SyncDtoMapper<Dao, Dto> {
  constructor(
    private dtoSample: Dto,
    private daoSample: Dao,
  ) {}

  public fromDao(dao: Dao, toAdd: Omitter<Dao, Dto>): Dto;
  public fromDao(dao: Dao | null, toAdd: Omitter<Dao, Dto>): Dto | null;
  public fromDao(dao: Dao | null, toAdd: Omitter<Dao, Dto>): Dto | null {
    if (dao) {
      return this.fromDaoUnsafe(dao, toAdd);
    } else {
      return null;
    }
  }

  public toDao(dto: Dto, toAdd: Omitter<Dto, Dao>): Dao;
  public toDao(dto: Dto | null, toAdd: Omitter<Dto, Dao>): Dao | null;
  public toDao(dto: Dto | null, toAdd: Omitter<Dto, Dao>): Dao | null {
    if (dto) {
      return genericMap<Dto, Dao>(dto, toAdd, this.daoSample);
    } else {
      return null;
    }
  }

  public fromPagedDao(pagedData: PagedData<Dao>, toAdd: Omitter<Dao, Dto>): PagedData<Dto> {
    return {
      //in pagedData content no null values are allowed
      content: pagedData?.content?.map((dao) => this.fromDaoUnsafe(dao, toAdd)),
      totalPages: pagedData?.totalPages,
      totalElements: pagedData?.totalElements,
      number: pagedData?.number,
      size: pagedData?.size,
      first: pagedData?.first,
      last: pagedData?.last,
    };
  }

  private fromDaoUnsafe(dao: Dao, toAdd: Omitter<Dao, Dto>): Dto {
    return genericMap<Dao, Dto>(dao, toAdd, this.dtoSample);
  }
}
