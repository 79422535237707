import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'spx-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
    standalone: false
})
export class TopbarComponent {
  @Output() toggleSidebar = new EventEmitter<void>();

  public toggle() {
    this.toggleSidebar.emit();
  }
}
