// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  height: 100%;
}

.logo {
  height: 25px;
  width: 117px;
}

.logo::before {
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  height: 30px;
  width: 117px;
}

mat-sidenav {
  border: unset;
  min-width: 250px;
}

/* stylelint-disable selector-class-pattern */
.sidenav__branding .sidenav__divider.no-branding {
  margin-bottom: 8px;
}

/* stylelint-enable selector-class-pattern */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/sidenav/components/sidenav/sidenav.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;AACF;;AAEA;EACE,4BAAA;EACA,wBAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,gBAAA;AACF;;AAEA,6CAAA;AAEE;EACE,kBAAA;AAAJ;;AAGA,4CAAA","sourcesContent":[":host {\n  display: flex;\n  height: 100%;\n}\n\n.logo {\n  height: 25px;\n  width: 117px;\n}\n\n.logo::before {\n  background-repeat: no-repeat;\n  background-size: contain;\n  content: '';\n  display: block;\n  height: 30px;\n  width: 117px;\n}\n\nmat-sidenav {\n  border: unset;\n  min-width: 250px;\n}\n\n/* stylelint-disable selector-class-pattern */\n.sidenav__branding {\n  .sidenav__divider.no-branding {\n    margin-bottom: 8px;\n  }\n}\n/* stylelint-enable selector-class-pattern */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
