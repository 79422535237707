// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.permission-mode-description {
  color: var(--app-white);
  position: absolute;
  text-align: center;
  top: 6px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/permission-overlay/permission-overlay.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;AACF","sourcesContent":[".permission-mode-description {\n  color: var(--app-white);\n  position: absolute;\n  text-align: center;\n  top: 6px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
