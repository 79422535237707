import { DaoType, DtoMapper, DtoType } from '../mapper';
import { PagedData } from '../paged-data';
import { UUID } from '../uuid';
import { CrudGetAllDto } from './crud-get-all-dto';
import { CrudService } from './crud.service';

export abstract class CrudController<Dao extends DaoType, Dto extends DtoType> {
  constructor(
    protected readonly service: CrudService<Dao, Dto>,
    protected readonly mapper: DtoMapper<Dao, Dto>,
  ) {}

  abstract getAll(query: CrudGetAllDto): Promise<PagedData<Dto>>;

  abstract getById(id: string): Promise<Dto>;

  abstract getByIds(ids: string[]): Promise<Dto[]>;

  abstract create(dto: Dto): Promise<Dto>;

  abstract update(dto: Dto): Promise<Dto>;

  abstract delete(id: string): Promise<UUID | null>;
}
