// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div,
img {
  height: 48px;
  width: 48px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/table-icon/table-icon.component.scss"],"names":[],"mappings":"AAAA;;EAEE,YAAA;EACA,WAAA;AACF","sourcesContent":["div,\nimg {\n  height: 48px;\n  width: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
