import { Component, EventEmitter, Input, Output } from '@angular/core';
import tinycolor from 'tinycolor2';

@Component({
    selector: 'shared-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    standalone: false
})
export class ColorPickerComponent {
  /**
   * Color of color picker
   */
  @Input() public color: string;

  /**
   * Disabled state of color picker
   */
  @Input() public disabled = false;

  /**
   * Preset colors
   */
  @Input() public presetColors = ['#5856d6', '#007aff', '#34aadc', '#5ac8fa', '#4cd964', '#ff2d55', '#ff3b30', '#ff9500', '#8e8e93'];

  /**
   * determines if the color input in the popup should be visible
   */
  @Input() public inputDisabled = false;

  @Input() public mode: 'color' | 'grayscale' | 'presets' = 'color';
  @Input() public position: string;
  @Input() public hideHueAlphaBox = false;

  /**
   * Output event, everytime the color changes
   */
  @Output() public colorChange: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Saves the color and emits the event
   * @param color Color of colorpicker
   */
  public saveColorValue(color: string): void {
    this.color = color;
    this.colorChange.emit(this.color);
  }

  public getMostReadableColor(hex: string): string {
    return tinycolor.mostReadable(hex, ['#fff', '#000']).toHexString();
  }
}
