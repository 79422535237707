// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable max-nesting-depth */
:host spx-icon {
  cursor: pointer;
  margin-right: 20px;
  position: relative;
  top: 4px;
  height: 20px;
}
:host spx-icon::ng-deep mat-icon {
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  font-size: 20px;
  height: 20px;
  width: 20px;
}
:host mat-form-field {
  width: 100%;
}
:host .range-selector-input-until {
  opacity: 0.64;
}
:host mat-form-field ::ng-deep {
  /* stylelint-disable-next-line selector-max-compound-selectors */
  /* stylelint-disable-next-line selector-max-compound-selectors */
  /* stylelint-disable-next-line selector-max-compound-selectors */
  /* stylelint-disable-next-line selector-max-compound-selectors */
}
:host mat-form-field ::ng-deep .mdc-text-field {
  padding: 0;
  width: 100%;
}
:host mat-form-field ::ng-deep .mat-mdc-form-field-infix {
  align-items: baseline;
  border-top: 0;
  display: flex;
  width: auto;
}
:host mat-form-field ::ng-deep .mat-mdc-form-field-subscript-wrapper {
  position: absolute;
  top: 144px;
  left: 5px;
}
:host mat-form-field ::ng-deep .hidden-range-input {
  display: none;
}

/* stylelint-enable max-nesting-depth */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/range-selector-input/range-selector-input.component.scss"],"names":[],"mappings":"AAAA,wCAAA;AAGE;EACE,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,QAAA;EACA,YAAA;AADJ;AAGI;EACE,qEAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;AADN;AAKE;EACE,WAAA;AAHJ;AAME;EACE,aAAA;AAJJ;AAOE;EACE,gEAAA;EAMA,gEAAA;EAQA,gEAAA;EAOA,gEAAA;AAvBJ;AAGI;EACE,UAAA;EACA,WAAA;AADN;AAKI;EACE,qBAAA;EACA,aAAA;EACA,aAAA;EACA,WAAA;AAHN;AAOI;EACE,kBAAA;EACA,UAAA;EACA,SAAA;AALN;AASI;EACE,aAAA;AAPN;;AAWA,uCAAA","sourcesContent":["/* stylelint-disable max-nesting-depth */\n\n:host {\n  spx-icon {\n    cursor: pointer;\n    margin-right: 20px;\n    position: relative;\n    top: 4px;\n    height: 20px;\n\n    &::ng-deep mat-icon {\n      /* stylelint-disable-next-line stylelint-plugins/no-font-properties */\n      font-size: 20px;\n      height: 20px;\n      width: 20px;\n    }\n  }\n\n  mat-form-field {\n    width: 100%;\n  }\n\n  .range-selector-input-until {\n    opacity: 0.64;\n  }\n\n  mat-form-field ::ng-deep {\n    /* stylelint-disable-next-line selector-max-compound-selectors */\n    .mdc-text-field {\n      padding: 0;\n      width: 100%;\n    }\n\n    /* stylelint-disable-next-line selector-max-compound-selectors */\n    .mat-mdc-form-field-infix {\n      align-items: baseline;\n      border-top: 0;\n      display: flex;\n      width: auto;\n    }\n\n    /* stylelint-disable-next-line selector-max-compound-selectors */\n    .mat-mdc-form-field-subscript-wrapper {\n      position: absolute;\n      top: 144px;\n      left: 5px;\n    }\n\n    /* stylelint-disable-next-line selector-max-compound-selectors */\n    .hidden-range-input {\n      display: none;\n    }\n  }\n}\n/* stylelint-enable max-nesting-depth */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
