import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ContentChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrandingDirective } from './branding.directive';

@Component({
    selector: 'spx-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    standalone: false
})
export class SidenavComponent {
  /**
   * Opened state of sidebar
   */
  public opened = true;

  /**
   * Mobile breakpoint stream
   */
  public isMobile: Observable<boolean>;

  @ContentChild(BrandingDirective, { static: true }) brandingTemplate?: BrandingDirective;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
  ) {
    this.isMobile = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(map((result) => result.matches));

    // close the sidebar on mobile
    this.isMobile.subscribe((isMobile) => {
      this.opened = !isMobile;
    });

    // close the sidebar, when a navigation was triggered on mobile
    combineLatest([this.isMobile, this.router.events]).subscribe(([isMobile, navigationEvent]) => {
      if (isMobile === true && navigationEvent instanceof NavigationEnd) {
        this.opened = false;
      }
    });
  }

  /**
   * Toggles the sidebar
   */
  toggle() {
    this.opened = !this.opened;
  }
}
