// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: inherit;
}

div {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.hide-click {
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/device-image/device-image.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,kCAAA;EACA,4BAAA;EACA,wBAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[":host {\n  height: inherit;\n}\n\ndiv {\n  background-position: center center;\n  background-repeat: no-repeat;\n  background-size: contain;\n  cursor: pointer;\n  height: 100%;\n  width: 100%;\n}\n\n.hide-click {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
