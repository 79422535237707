// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-snack-bar {
  align-items: start;
  place-content: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  gap: 16px;
  /* stylelint-disable-next-line selector-max-compound-selectors */
}
.custom-snack-bar .title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.custom-snack-bar .cross-icon {
  margin-left: auto;
}
.custom-snack-bar .button-row {
  display: flex;
  flex-direction: row;
  place-content: end;
  align-items: center;
  width: 100%;
}
.custom-snack-bar .custom-button ::ng-deep button {
  color: inherit;
}

/* stylelint-disable-next-line selector-class-pattern */
::ng-deep .spx-notification.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: unset;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/notification/notification.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,aAAA;EACA,SAAA;EAqBA,gEAAA;AAnBF;AAAE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;AAEJ;AACE;EACE,iBAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAIE;EACE,cAAA;AAFJ;;AAMA,uDAAA;AACA;EACE,uBAAA;AAHF","sourcesContent":[".custom-snack-bar {\n  align-items: start;\n  place-content: center;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: 1rem;\n  gap: 16px;\n\n  .title-row {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n  }\n\n  .cross-icon {\n    margin-left: auto;\n  }\n\n  .button-row {\n    display: flex;\n    flex-direction: row;\n    place-content: end;\n    align-items: center;\n    width: 100%;\n  }\n\n  /* stylelint-disable-next-line selector-max-compound-selectors */\n  .custom-button ::ng-deep button {\n    color: inherit;\n  }\n}\n\n/* stylelint-disable-next-line selector-class-pattern */\n::ng-deep .spx-notification.mat-mdc-snack-bar-container .mdc-snackbar__surface {\n  background-color: unset;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
