import { DOCUMENT } from '@angular/common';
import { EnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { HotjarFunction } from '../model/hotjar-function';
import { HotjarSettings } from '../model/hotjar-settings';
import { HJ_SETTINGS } from './hotjar-settings.provider';
import { HotjarUserAttributesLoader } from './hotjar-user-attributes.provider';
import { HJ_WINDOW } from './hotjar-window.provider';

/**
 * This is the hotjar initializer, which should only be called once on initialization
 *
 * @param settings Hotjar Settings
 * @param document Document instance
 * @param window Window instance
 * @returns
 */
export function hotjarInitializer(
  settings: HotjarSettings,
  document: Document,
  window: (Window & { hj?: HotjarFunction; _hjSettings?: { hjid: string; hjsv: number } }) | null,
  hotjarUserAttributesLoader: HotjarUserAttributesLoader,
) {
  return () => {
    // if settings are missing, log the error and return
    if (!settings?.trackingCode) {
      console.error('Empty tracking code for Hotjar. Please provide one when importing ther HotjarModule.forRoot()');
      return;
    }

    // if settings are missing, log the error and return
    if (!window) {
      console.error('Please make sure the environment is a browser. Was not possible to access `document` instance');
      return;
    }

    // if settings are missing, log the error and return
    if (!document) {
      console.error('Please make sure the environment is a browser. Was not possible to access `window` instance');
      return;
    }

    // define the window hotjar property
    Object.defineProperty(window, 'hj', {
      value:
        window.hj ||
        function () {
          if (window.hj) {
            // eslint-disable-next-line prefer-rest-params
            (window.hj.q = window.hj.q || []).push(arguments);
          }
        },
      configurable: true,
      writable: true,
    });

    // define the window hotjar settings property. That was neccessary, because the hotjar script is using these settings
    Object.defineProperty(window, '_hjSettings', {
      value: { hjid: settings.trackingCode, hjsv: settings.version || 6 },
      configurable: true,
      writable: true,
    });

    // Create the hotjar script tag and append it to the head
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://static.hotjar.com/c/hotjar-${window?._hjSettings?.hjid}.js?sv=${window?._hjSettings?.hjsv}`;

    if (settings.enabled) {
      head?.appendChild(script);

      // set user attributes, when user data are set
      hotjarUserAttributesLoader.getUserAttributes().subscribe((userAttributes) => {
        if (window.hj && userAttributes?.id) {
          window?.hj('identify', userAttributes.id, userAttributes.attributes);
        }
      });
    }
  };
}

/**
 * Provides a TOKEN to manually configure Hojtar tracking code by angular way.
 */
export const HJ_PROVIDER: EnvironmentProviders = provideAppInitializer(() => {
  const initializerFn = hotjarInitializer(inject(HJ_SETTINGS), inject(DOCUMENT), inject(HJ_WINDOW), inject(HotjarUserAttributesLoader));
  return initializerFn();
});
