// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first-row-wrapper {
  display: flex;
  gap: 8px;
  margin-bottom: 0.5rem;
}

h1 {
  margin: 0 1rem 1px 0;
}

.action-card {
  width: auto;
}

.comm-nr-link {
  cursor: pointer;
  text-decoration: underline;
}
.comm-nr-link:hover {
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  font-weight: bolder;
}

.dev-status {
  height: 3rem;
  width: 3rem;
}

.dev-alarm-status {
  height: 1rem;
  width: 1rem;
}

.description {
  margin-top: 10px;
}

.alarms {
  cursor: pointer;
}

.card-status-icon {
  margin-right: 1rem;
}

.connectivity-bar {
  height: 35px;
  margin: 0;
  padding: 0;
  width: 150px;
}

.values {
  margin-right: 0.5rem;
}

.assigned-devices-container {
  margin-top: 10px;
}

.serial-number {
  margin-bottom: 0.5rem;
  display: flex;
}

.copy-content-container {
  display: none;
}

.visible {
  display: inline;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/device-detail-title/device-detail-title.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,qBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,eAAA;EACA,0BAAA;AACF;AACE;EACE,qEAAA;EACA,mBAAA;AACJ;;AAGA;EACE,YAAA;EACA,WAAA;AAAF;;AAGA;EACE,YAAA;EACA,WAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,YAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;AAAF;;AAGA;EACE,oBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,qBAAA;EACA,aAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,eAAA;AAAF","sourcesContent":[".first-row-wrapper {\n  display: flex;\n  gap: 8px;\n  margin-bottom: 0.5rem;\n}\n\nh1 {\n  margin: 0 1rem 1px 0;\n}\n\n.action-card {\n  width: auto;\n}\n\n.comm-nr-link {\n  cursor: pointer;\n  text-decoration: underline;\n\n  &:hover {\n    /* stylelint-disable-next-line stylelint-plugins/no-font-properties */\n    font-weight: bolder;\n  }\n}\n\n.dev-status {\n  height: 3rem;\n  width: 3rem;\n}\n\n.dev-alarm-status {\n  height: 1rem;\n  width: 1rem;\n}\n\n.description {\n  margin-top: 10px;\n}\n\n.alarms {\n  cursor: pointer;\n}\n\n.card-status-icon {\n  margin-right: 1rem;\n}\n\n.connectivity-bar {\n  height: 35px;\n  margin: 0;\n  padding: 0;\n  width: 150px;\n}\n\n.values {\n  margin-right: 0.5rem;\n}\n\n.assigned-devices-container {\n  margin-top: 10px;\n}\n\n.serial-number {\n  margin-bottom: 0.5rem;\n  display: flex;\n}\n\n.copy-content-container {\n  display: none;\n}\n\n.visible {\n  display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
