/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { ButtonType, Color } from '../../model';
import { IconComponent } from '../icon';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
    selector: 'spx-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    standalone: false
})
export class ButtonComponent implements AfterContentInit, AfterViewInit, OnChanges {
  // Icon component, if present
  @ContentChild(IconComponent) icon!: IconComponent;
  @ContentChild(SpinnerComponent) spinner!: SpinnerComponent;

  /**
   * Type of button
   */
  @Input() type: ButtonType = 'mat-button';

  /**
   * Color of button
   */
  @Input() color: Color = 'primary';

  /**
   * Disabled state of button
   */
  @Input() disabled = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngAfterContentInit(): void {
    // if icon is present below the Button Component, then set the properties according to the button component
    if (this.icon) {
      this.icon.color = this.color;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      const pointerEventsValue = this.disabled ? 'none' : 'unset';
      this.el.nativeElement.setAttribute('style', `pointer-events: ${pointerEventsValue}`);
    }
  }

  ngAfterViewInit(): void {
    // Angular Materials mat button wrapper element
    const buttonWrapper = this.el.nativeElement.querySelector('.mdc-button');

    // icon element
    let iconElement = buttonWrapper.querySelector('mat-icon');
    const labelElement = buttonWrapper.querySelector('.mdc-button__label');

    if (!iconElement) {
      iconElement = buttonWrapper.querySelector('mat-spinner');
    }

    // if the button wrapper has more than 1 child or the inner text is set as no iconname, we need a margin between the icon and the other content
    if (
      iconElement &&
      buttonWrapper.innerText?.length > 0 &&
      (labelElement?.children.length > 1 || this.icon?.name !== buttonWrapper.innerText?.trim())
    ) {
      const buttonFontSize = parseInt(window.getComputedStyle(buttonWrapper).getPropertyValue('font-size'));

      // child nodes helper array
      const childNodesArray = Array.from(labelElement.childNodes);

      // icon position in childNodes
      const iconPosition = childNodesArray.findIndex((cN: any) => cN.nodeName === 'SPX-ICON' || cN.nodeName === 'SPX-SPINNER');

      // content position in childNodes
      const contentPosition = childNodesArray.findIndex((cN: any) => cN.nodeName === '#text' || cN.nodeName === 'SPAN');

      // set margin to icon, based on its position to the content
      if (iconPosition > contentPosition) {
        this.renderer.setStyle(iconElement, 'margin-left', '8px');
      } else {
        this.renderer.setStyle(iconElement, 'margin-right', '8px');
      }
      this.renderer.setStyle(iconElement, 'font-size', `${buttonFontSize * 1.2}px`);
      this.renderer.setStyle(iconElement, 'height', `${buttonFontSize * 1.2}px`);
      this.renderer.setStyle(iconElement, 'width', `${buttonFontSize * 1.2}px`);
      this.renderer.setStyle(iconElement, 'line-height', `${buttonFontSize * 1.2}px`);
    }
  }
}
