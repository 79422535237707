import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import packageJson from '../../../../../package.json';
import { TenantConfigService } from '../../../shared/services/tenant-config.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    standalone: false
})

export class LayoutComponent {
  public imprintUrl: string;
  public websiteUrl: string;
  public appVersionString: string = 'v' + packageJson.version;
  public pageTitle: string;
  public isHome: boolean;
  public hideElements: boolean = false;

  constructor(
    private tenantConfigService: TenantConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.imprintUrl = this.tenantConfigService.getImprintUrl();
    this.websiteUrl = this.tenantConfigService.getAppUrl();
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      let route = this.activatedRoute;
      // iterate through route children to get latest route data object
      while (route.firstChild) {
        route = route.firstChild;
      }
      if (route.snapshot.data.pageTitle) {
        this.pageTitle = route.snapshot.data.pageTitle;
      } else {
        this.pageTitle = this.activatedRoute.firstChild?.snapshot.data.sectionTitle || '';
      }
      if (this.router.url.startsWith('/home')) {
        this.isHome = true;
      } else {
        this.isHome = false;
      }

      this.hideElements = this.router.url.includes('/auth/create-new-password');
    });
  }
}
