// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  margin-top: 2rem;
  max-width: 400px;
  /* stylelint-disable-next-line selector-max-compound-selectors */
}
.wrapper mat-form-field {
  width: 100%;
}
.wrapper mat-form-field textarea {
  overflow: hidden;
}
.wrapper mat-form-field .error {
  color: var(--warn-default);
}
.wrapper .button-wrapper {
  margin-left: 1rem;
  margin-top: auto;
  padding-bottom: 15px;
}
.wrapper .button-wrapper .md-button {
  min-width: 1%;
}
.wrapper .button-wrapper .md-button .icon {
  padding: 0;
}
.wrapper .uploader-wrapper {
  max-width: calc(100% - 2rem);
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/upload-dialog/upload-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;EAwBA,gEAAA;AAtBF;AAAE;EACE,WAAA;AAEJ;AACE;EACE,gBAAA;AACJ;AAEE;EACE,0BAAA;AAAJ;AAGE;EACE,iBAAA;EACA,gBAAA;EACA,oBAAA;AADJ;AAIE;EACE,aAAA;AAFJ;AAME;EACE,UAAA;AAJJ;AAOE;EACE,4BAAA;AALJ","sourcesContent":[".wrapper {\n  margin-top: 2rem;\n  max-width: 400px;\n\n  mat-form-field {\n    width: 100%;\n  }\n\n  mat-form-field textarea {\n    overflow: hidden;\n  }\n\n  mat-form-field .error {\n    color: var(--warn-default);\n  }\n\n  .button-wrapper {\n    margin-left: 1rem;\n    margin-top: auto;\n    padding-bottom: 15px;\n  }\n\n  .button-wrapper .md-button {\n    min-width: 1%;\n  }\n\n  /* stylelint-disable-next-line selector-max-compound-selectors */\n  .button-wrapper .md-button .icon {\n    padding: 0;\n  }\n\n  .uploader-wrapper {\n    max-width: calc(100% - 2rem);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
