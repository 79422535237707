// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host mat-form-field {
  width: 100%;
}
:host .metadata-cards {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  list-style: none;
  margin: 16px 0 0;
  padding: 0;
}
:host .new-metadata-card {
  margin: 16px 0;
}
:host mat-card {
  padding: 16px 16px 0;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/metadata/metadata.component.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAGE;EACE,aAAA;EACA,SAAA;EACA,4DAAA;EACA,gBAAA;EACA,gBAAA;EACA,UAAA;AADJ;AAIE;EACE,cAAA;AAFJ;AAKE;EACE,oBAAA;AAHJ","sourcesContent":[":host {\n  mat-form-field {\n    width: 100%;\n  }\n\n  .metadata-cards {\n    display: grid;\n    gap: 16px;\n    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));\n    list-style: none;\n    margin: 16px 0 0;\n    padding: 0;\n  }\n\n  .new-metadata-card {\n    margin: 16px 0;\n  }\n\n  mat-card {\n    padding: 16px 16px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
