import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GenericEditDialogData } from '../../models/generic-edit-dialog-data.model';
import { IrdProperty } from '@ird/shared-base';
import { noUndefined } from '@ird/shared-utils';
import { GenericAdminService, ValidatorService } from '../../services';
import { IconPickerOptions, SelectOptions } from '../../models';

enum Action {
  SAVE = 'SAVE',
  CANCEL = 'CANCEL',
}

@Component({
    selector: 'shared-generic-edit-dialog',
    templateUrl: './generic-edit-dialog.component.html',
    styleUrls: ['./generic-edit-dialog.component.scss'],
    standalone: false
})
export class GenericEditDialogComponent<Dto> implements OnInit {
  formGroup: FormGroup;
  data: Dto;
  visibleProperties: IrdProperty<any, any>[] = [];

  private disabledState$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: GenericEditDialogData<Dto>,
    private dialogRef: MatDialogRef<GenericEditDialogComponent<Dto>>,
    private formBuilder: FormBuilder,
    private genericAdminService: GenericAdminService<Dto>,
    private validatorService: ValidatorService,
  ) {
    this.dialogData.actions = [
      {
        text: 'BUTTON_CANCEL',
        color: 'primary',
        type: 'mat-stroked-button',
        action: () => {
          this.close(Action.CANCEL);
          return Subscription.EMPTY;
        },
      },
      {
        text: 'BUTTON_SAVE',
        color: 'primary',
        type: 'mat-raised-button',
        action: () => {
          this.close(Action.SAVE);
          return Subscription.EMPTY;
        },
        disable: () => {
          return this.disabledState$.asObservable();
        },
      },
    ];

    this.data = this.dialogData.data;
    this.visibleProperties = this.genericAdminService.extractVisibleProperties(this.dialogData.metadata);

    this.initFormGroup();

    // Subscribe to change event
    this.formGroup.valueChanges.subscribe(() => {
      this.disabledState$.next(this.saveDisabled());
    });
  }

  ngOnInit(): void {
    this.loadFormFieldValues();

    this.saveDisabled();
  }

  getSelectOptions(name: string): SelectOptions {
    return {
      options: (this.dialogData.options[name] as SelectOptions)?.options ?? undefined,
    };
  }

  getIconPickerOptions(name: string): IconPickerOptions {
    let url = '';

    if (this.dialogData.options[name]['url']) {
      url = this.dialogData.options[name]['url'];
    } else {
      if (this.dialogData.options[name]['urlFn'] && this.dialogData.options[name]['urlFn'] instanceof Function) {
        url = this.dialogData.options[name]['urlFn'](this.dialogData.dtoId);
      }
    }

    return {
      url: url ?? undefined,
    };
  }

  setIcon(name: string, event?: any): void {
    this.formGroup.get(name).setValue(event ? event.image : undefined);
    this.disabledState$.next(this.saveDisabled());
  }

  initFormGroup(): void {
    if (this.dialogData.metadata) {
      const group = {};

      Object.values(this.dialogData.metadata.properties).forEach((item: IrdProperty<any, any>) => {
        group[item.name] = new FormControl(null, this.validatorService.mapValidator(item.validator));
      });

      this.formGroup = this.formBuilder.group(group);
    }
  }

  private loadFormFieldValues(): void {
    if (this.dialogData.data) {
      const group = {};
      Object.values(this.dialogData.metadata.properties).forEach((item: IrdProperty<any, any>) => {
        if (item.type === 'json') {
          group[this.genericAdminService.getPropertyName(item)] = this.genericAdminService.formatJson(
            this.dialogData.data[this.genericAdminService.getPropertyName(item)],
          );
        } else {
          group[this.genericAdminService.getPropertyName(item)] =
            this.dialogData.data[this.genericAdminService.getPropertyName(item)] ?? null;
        }
      });
      this.formGroup.setValue(group);
    }
  }

  private close(action: Action): void {
    if (action === Action.SAVE) {
      this.dialogRef.close(noUndefined({ ...this.data, ...this.formGroup.value }));
    } else {
      this.dialogRef.close();
    }
  }

  private saveDisabled(): boolean {
    const invalidItems: string[] = [];
    Object.keys(this.formGroup.value).forEach((item) => {
      if (this.formGroup.get(item)?.invalid) {
        invalidItems.push(item);
      }
    });
    return invalidItems.length > 0;
  }
}
