// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
}

table {
  width: 100%;
}

.no-data {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

:host.spx-selectable table tr.spx-row:hover,
:host.spx-expandable table tr.spx-row:hover {
  cursor: pointer;
}

:host.spx-draggable table tr.spx-row.spx-draggable {
  cursor: grab;
}

:host.spx-draggable table tr.spx-row.spx-dragging {
  cursor: grabbing;
}

/* stylelint-disable-next-line selector-max-compound-selectors */
:host ::ng-deep .spx-row td.spx-cell {
  border-bottom-width: 0;
}

tr.spx-detail-row {
  height: 0;
}

.element-detail {
  display: flex;
  overflow: hidden;
}

.spx-expandable-arrow-cell,
.spx-selection-checkbox-cell {
  padding-right: 16px;
  width: 24px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/table/components/table/table.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,OAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;EACA,WAAA;AACF;;AAEA;;EAEE,eAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA,gEAAA;AACA;EACE,sBAAA;AACF;;AAEA;EACE,SAAA;AACF;;AAEA;EACE,aAAA;EACA,gBAAA;AACF;;AAEA;;EAEE,mBAAA;EACA,WAAA;AACF","sourcesContent":[":host {\r\n  display: flex;\r\n  flex-direction: column;\r\n  overflow: auto;\r\n  position: relative;\r\n}\r\n\r\ntable {\r\n  width: 100%;\r\n}\r\n\r\n.no-data {\r\n  align-items: center;\r\n  display: flex;\r\n  height: 100%;\r\n  justify-content: center;\r\n  left: 0;\r\n  position: absolute;\r\n  top: 0;\r\n  width: 100%;\r\n  z-index: 10;\r\n}\r\n\r\n:host.spx-selectable table tr.spx-row:hover,\r\n:host.spx-expandable table tr.spx-row:hover {\r\n  cursor: pointer;\r\n}\r\n\r\n:host.spx-draggable table tr.spx-row.spx-draggable {\r\n  cursor: grab;\r\n}\r\n\r\n:host.spx-draggable table tr.spx-row.spx-dragging {\r\n  cursor: grabbing;\r\n}\r\n\r\n/* stylelint-disable-next-line selector-max-compound-selectors */\r\n:host ::ng-deep .spx-row td.spx-cell {\r\n  border-bottom-width: 0;\r\n}\r\n\r\ntr.spx-detail-row {\r\n  height: 0;\r\n}\r\n\r\n.element-detail {\r\n  display: flex;\r\n  overflow: hidden;\r\n}\r\n\r\n.spx-expandable-arrow-cell,\r\n.spx-selection-checkbox-cell {\r\n  padding-right: 16px;\r\n  width: 24px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
