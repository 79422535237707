import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { concat, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogAction } from '../../model/dialog.actions';

@Component({
    selector: 'spx-dialog-action',
    templateUrl: './dialog-action.component.html',
    standalone: false
})
export class DialogActionComponent implements OnInit {
  /**
   * Loading indicator to disable interactions
   */
  @Input() isLoading = false;

  /**
   * Action
   */
  @Input() dialogAction: DialogAction = {
    text: '',
    type: 'mat-button',
    color: 'primary',
  };

  /**
   * Action fired event
   */
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Disabled Stream for button disabled state
   */
  public disabled$!: Observable<boolean>;

  ngOnInit(): void {
    this.disabled$ = this.dialogAction.disable ? this.getDisabledStream(this.dialogAction.disable()) : of(false);
  }

  /**
   * Fires the action on click event
   */
  public onAction(): void {
    this.loading.emit(true);

    if (this.dialogAction.action) {
      this.dialogAction.action().add(() => this.loading.emit(false));
    }
  }

  /**
   * Converts the provided input to a disables stream to control the disabled state of the button
   *
   * @param dialogDisable dialog diable input. Can be a boolean observable or Angular FormGroup / FormControl
   * @returns disabled stream
   */
  private getDisabledStream(dialogDisable: Observable<boolean> | FormGroup | FormControl): Observable<boolean> {
    if (dialogDisable instanceof FormGroup || dialogDisable instanceof FormControl) {
      // set inital value
      const initialValue = of(dialogDisable.pristine || dialogDisable.invalid);

      // setting the status change stream and map the result
      const statusChange = dialogDisable.statusChanges.pipe(
        map((status) => {
          return dialogDisable.pristine ? true : status === 'INVALID';
        })
      );

      // emit the initial value first and afterwards emit the status changes from the group / control
      return concat(initialValue, statusChange);
    }

    return dialogDisable;
  }
}
