import { Component, ContentChildren, Inject, Input, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@ird/ng-core';

@Component({
    selector: 'spx-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    standalone: false
})
export class MenuItemComponent {
  /**
   * Icon for menu item
   */
  @Input() icon!: string;

  /**
   * Icon for menu item as custom file url
   */
  @Input() iconFile!: string;

  /**
   * Icon namespace
   */
  @Input() iconNamespace!: string;

  /**
   * Translation key for menu items
   */
  @Input() translationKey!: string;

  /**
   * Translation parameter for menu items
   */
  @Input() translationParameter!: string;

  /**
   * Route to page
   */
  @Input() route!: string;

  /**
   * All possible descendent menu items (currently one level is supported)
   */
  @ContentChildren(MenuItemComponent, { descendants: true }) contentChildren!: QueryList<MenuItemComponent>;

  /**
   * Child variable for styling
   */
  public child = false;

  /**
   * Active variable for styling
   */
  public active = false;

  /**
   * Active variable to expand possible sub menu items
   */
  public expanded = false;

  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router,
  ) {}

  /**
   * Toggles the possible sub menu
   */
  public toggleSubMenuExpand(): void {
    this.expanded = !this.expanded;
  }
}
