import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
    selector: '[sharedDragAndDropFile]',
    standalone: false
})
export class DragAndDropFileDirective {
  @Input()
  @HostBinding('class.fileover')
  fileOver: boolean;
  @Input() disabled = false;

  @Output() fileDropped = new EventEmitter<any>();
  @Output() fileOverChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Dragover listener
  @HostListener('dragover', ['$event'])
  onDragOver(evt): void {
    if (this.disabled) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
    this.fileOverChange.emit(this.fileOver);
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    this.fileOverChange.emit(this.fileOver);
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt) {
    if (this.disabled) {
      return;
    }

    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    this.fileOverChange.emit(this.fileOver);
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
