// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable stylelint-plugins/no-hardcoded-colors */
/* stylelint-disable selector-class-pattern */
.spx-card {
  padding: 0;
}

.spx-card__header {
  background-image: linear-gradient(90deg, #05143c 60%, #7092f0);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #fff;
  padding: 0.5rem 0;
  width: 100%;
}

.spx-card__status {
  height: 14px;
}

.spx-card__image {
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: 1px #ddd solid;
  cursor: pointer;
  display: block;
  margin: auto;
  object-fit: contain;
  width: 100%;
}

.spx-card__content {
  margin: 0;
  padding: 1rem;
}

.spx-card__actions__content {
  width: 100%;
}

.spx-card__actions--empty {
  display: none;
}

/* stylelint-enable stylelint-plugins/no-hardcoded-colors  */
/* stylelint-enable selector-class-pattern */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/base/card/card.component.scss"],"names":[],"mappings":"AAAA,4DAAA;AACA,6CAAA;AAEA;EACE,UAAA;AAAF;;AAGA;EACE,8DAAA;EACA,2BAAA;EACA,4BAAA;EACA,WAAA;EACA,iBAAA;EACA,WAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,sBAAA;EACA,2BAAA;EACA,4BAAA;EACA,6BAAA;EACA,eAAA;EACA,cAAA;EACA,YAAA;EACA,mBAAA;EACA,WAAA;AAAF;;AAGA;EACE,SAAA;EACA,aAAA;AAAF;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAGA,4DAAA;AACA,4CAAA","sourcesContent":["/* stylelint-disable stylelint-plugins/no-hardcoded-colors */\n/* stylelint-disable selector-class-pattern */\n\n.spx-card {\n  padding: 0;\n}\n\n.spx-card__header {\n  background-image: linear-gradient(90deg, #05143c 60%, #7092f0);\n  border-top-left-radius: 4px;\n  border-top-right-radius: 4px;\n  color: #fff;\n  padding: 0.5rem 0;\n  width: 100%;\n}\n\n.spx-card__status {\n  height: 14px;\n}\n\n.spx-card__image {\n  background-color: #fff;\n  background-position: center;\n  background-repeat: no-repeat;\n  border-bottom: 1px #ddd solid;\n  cursor: pointer;\n  display: block;\n  margin: auto;\n  object-fit: contain;\n  width: 100%;\n}\n\n.spx-card__content {\n  margin: 0;\n  padding: 1rem;\n}\n\n.spx-card__actions__content {\n  width: 100%;\n}\n\n.spx-card__actions--empty {\n  display: none;\n}\n\n/* stylelint-enable stylelint-plugins/no-hardcoded-colors  */\n/* stylelint-enable selector-class-pattern */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
