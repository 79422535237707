import { Directive, HostListener } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Directive({
    selector: '[backButton]',
    standalone: false
})
export class BackbuttonDirective {
  constructor(private navigation: NavigationService) {}

  @HostListener('click')
  onClick() {
    this.navigation.back();
  }
}
