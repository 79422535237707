// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable selector-class-pattern */
:host {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}

mat-progress-bar {
  position: absolute;
}

spx-dialog-action:not(:last-child) {
  padding-right: 4px;
}

spx-dialog-action:not(:first-child) {
  padding-left: 4px;
}

.dialog__title {
  padding: 12px 24px;
}
.dialog__title spx-icon {
  margin-left: 12px;
}

.mdc-dialog__title,
.mdc-dialog__content {
  padding: 0;
}

.mdc-dialog__actions {
  padding: 12px 22px;
}

.dialog__content {
  height: 100%;
  padding: 0 24px;
}

.dialog__content.no-padding-content {
  padding: 0;
}

.floating-close-button {
  right: 16px;
  position: absolute;
  top: 16px;
  z-index: 1;
}

.dialog__content__container {
  padding: 12px 0;
}

/* stylelint-enable selector-class-pattern */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/dialog/components/dialog/dialog.component.scss"],"names":[],"mappings":"AAAA,6CAAA;AACA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,kBAAA;AACF;AACE;EACE,iBAAA;AACJ;;AAGA;;EAEE,UAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,YAAA;EACA,eAAA;AAAF;;AAGA;EACE,UAAA;AAAF;;AAGA;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAEA,4CAAA","sourcesContent":["/* stylelint-disable selector-class-pattern */\n:host {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  position: relative;\n  width: 100%;\n}\n\nmat-progress-bar {\n  position: absolute;\n}\n\nspx-dialog-action:not(:last-child) {\n  padding-right: 4px;\n}\n\nspx-dialog-action:not(:first-child) {\n  padding-left: 4px;\n}\n\n.dialog__title {\n  padding: 12px 24px;\n\n  spx-icon {\n    margin-left: 12px;\n  }\n}\n\n.mdc-dialog__title,\n.mdc-dialog__content {\n  padding: 0;\n}\n\n.mdc-dialog__actions {\n  padding: 12px 22px;\n}\n\n.dialog__content {\n  height: 100%;\n  padding: 0 24px;\n}\n\n.dialog__content.no-padding-content {\n  padding: 0;\n}\n\n.floating-close-button {\n  right: 16px;\n  position: absolute;\n  top: 16px;\n  z-index: 1;\n}\n\n.dialog__content__container {\n  padding: 12px 0;\n}\n/* stylelint-enable selector-class-pattern */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
