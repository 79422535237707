// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .expand-hide {
  max-height: 50px;
  overflow: hidden;
}
:host ::ng-deep .expand-hide spx-icon-button {
  display: none;
}`, "",{"version":3,"sources":["webpack://./apps/scs-service-point/src/app/layout/components/navigation/navigation.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,aAAA;AADJ","sourcesContent":[":host {\n  .expand-hide {\n    max-height: 50px;\n    overflow: hidden;\n  }\n\n  ::ng-deep .expand-hide spx-icon-button {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
