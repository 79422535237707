// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
  /* stylelint-disable-next-line */
}
:host a {
  color: inherit;
  text-decoration: none;
}
:host .clickable {
  cursor: pointer;
}
:host .hide-in-percy {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 50px;
}
:host spx-icon ::ng-deep mat-icon {
  height: 18px;
  width: 18px;
}
:host .type-icon {
  margin-right: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/base/breadcrumbs/breadcrumbs.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EAkBA,gCAAA;AAhBF;AAAE;EACE,cAAA;EACA,qBAAA;AAEJ;AACE;EACE,eAAA;AACJ;AAEE;EACE,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAIE;EACE,YAAA;EACA,WAAA;AAFJ;AAKE;EACE,oBAAA;AAHJ","sourcesContent":[":host {\n  display: block;\n  width: 100%;\n\n  a {\n    color: inherit;\n    text-decoration: none;\n  }\n\n  .clickable {\n    cursor: pointer;\n  }\n\n  .hide-in-percy {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    width: 50px;\n  }\n\n  /* stylelint-disable-next-line */\n  spx-icon ::ng-deep mat-icon {\n    height: 18px;\n    width: 18px;\n  }\n\n  .type-icon {\n    margin-right: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
