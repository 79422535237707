import { Component } from '@angular/core';
import { PermissionOverlayService } from '../../services';

@Component({
    selector: 'shared-overlay-button',
    templateUrl: './overlay-button.component.html',
    styleUrls: ['overlay-button.component.scss'],
    standalone: false
})
export class OverlayButtonComponent {
  constructor(private permissionOverlayService: PermissionOverlayService) {}

  public toggleOverlay(): void {
    this.permissionOverlayService.toggle();
  }
}
