export type Promisified<T> = {
  [K in keyof T]: T[K] extends (firstArg: infer F, ...args: infer A) => infer R ? (firstArg: Promise<F>, ...args: A) => Promise<R> : T[K];
};

export class AsyncMapperFactory {
  static create<T extends object>(mapper: T) {
    return new Proxy<T>(mapper, {
      get(target, prop, receiver) {
        return (promise: Promise<any>, toAdd: any) => {
          return promise.then((value) => {
            const result = mapper[prop](value, toAdd);
            return result;
          });
        };
      },
    }) as unknown as Promisified<T>;
  }
}
