import { Inject, Pipe, PipeTransform } from '@angular/core';
import { WINDOW } from '../providers/window';

/**
 * IsExternalUrl Pipe
 *
 * This pipe checks, if an url is an external one
 */
@Pipe({
    name: 'isExternalUrl',
    standalone: false
})
export class IsExternalUrlPipe implements PipeTransform {
  constructor(@Inject(WINDOW) private window: Window) {}

  /**
   * Checks the provided url for external
   *
   * @param url url to check
   * @returns boolean, if the provided url is an external one
   */
  transform(url: string): boolean {
    if (!url) {
      return false;
    }

    return this.isNewExternalUrl(url);
  }

  /**
   * Checks if the provided url is an external one and different to the current location
   *
   * @param url url to test
   * @returns boolean of new external url
   */
  private isNewExternalUrl(url: string): boolean {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return !url.startsWith(this.window.location.origin);
    }

    return false;
  }
}
