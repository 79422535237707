import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: 'input[spxOnlyInteger]',
    standalone: false
})
export class IntegerInputDirective {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @HostListener('keypress', ['$event']) onKeyPress(event: any) {
    const enteredChar = parseInt(event.charCode, 10);
    return enteredChar >= 47 && enteredChar <= 57;
  }
}
