import { Component, Input } from '@angular/core';
import { Shape } from '../../models/placeholder-shape.enum';

@Component({
    selector: 'shared-placeholder-image',
    templateUrl: './placeholder-image.component.html',
    standalone: false
})
export class PlaceholderImageComponent {
  Shape = Shape

  @Input()
  size: { height: string; width: string };
  @Input()
  shape: Shape = Shape.CIRCLE;
}
