// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: auto;
  position: relative;
  /* stylelint-disable-next-line */
}
:host > div {
  height: 100%;
  width: 100%;
}
:host .app-color-picker {
  /* stylelint-disable-next-line stylelint-plugins/no-hardcoded-colors */
  border-color: rgb(219, 219, 219);
  border-style: solid;
  border-width: 4px;
  cursor: pointer;
  display: flex;
  min-height: 15px;
  min-width: 15px;
  position: unset;
}
:host .app-color-picker--disabled {
  border: 0;
  cursor: default;
}
:host .app-color-picker .content {
  margin: auto 8px;
  width: 100%;
}
:host .hide-hue-alpha ::ng-deep {
  /* stylelint-disable-next-line */
}
:host .hide-hue-alpha ::ng-deep .hue-alpha,
:host .hide-hue-alpha ::ng-deep hr {
  display: none;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/color-picker/color-picker.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EA6BA,gCAAA;AA3BF;AAAE;EACE,YAAA;EACA,WAAA;AAEJ;AACE;EACE,sEAAA;EACA,gCAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;AACJ;AAEE;EACE,SAAA;EACA,eAAA;AAAJ;AAGE;EACE,gBAAA;EACA,WAAA;AADJ;AAKE;EACE,gCAAA;AAHJ;AAII;;EAEE,aAAA;AAFN","sourcesContent":[":host {\n  height: auto;\n  position: relative;\n\n  > div {\n    height: 100%;\n    width: 100%;\n  }\n\n  .app-color-picker {\n    /* stylelint-disable-next-line stylelint-plugins/no-hardcoded-colors */\n    border-color: rgb(219, 219, 219);\n    border-style: solid;\n    border-width: 4px;\n    cursor: pointer;\n    display: flex;\n    min-height: 15px;\n    min-width: 15px;\n    position: unset;\n  }\n\n  .app-color-picker--disabled {\n    border: 0;\n    cursor: default;\n  }\n\n  .app-color-picker .content {\n    margin: auto 8px;\n    width: 100%;\n  }\n\n  /* stylelint-disable-next-line */\n  .hide-hue-alpha ::ng-deep {\n    /* stylelint-disable-next-line */\n    .hue-alpha,\n    hr {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
