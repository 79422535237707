// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  background-color: var(--black-a9);
  color: var(--white);
  width: 100%;
  /* stylelint-disable selector-max-compound-selectors */
  /* stylelint-disable stylelint-plugins/no-font-properties */
}
:host .info {
  padding: 12px;
}
:host ::ng-deep .info mat-icon {
  font-size: 32px;
  line-height: 32px;
  height: 32px;
  width: 32px;
}
:host img {
  max-width: 100%;
  max-height: 100%;
}
:host .close {
  cursor: pointer;
}
:host .navigation {
  align-items: center;
  background-color: var(--black-a5);
  cursor: pointer;
  top: 50%;
  position: absolute;
  z-index: 500;
}
:host :host ::ng-deep .navigation mat-icon {
  font-size: 48px;
  line-height: 48px;
  height: 48px;
  width: 48px;
}
:host .navigation.left {
  left: 0;
}
:host .navigation.right {
  right: 0;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/image-slider/image-slider.component.scss"],"names":[],"mappings":"AAAA;EACE,iCAAA;EACA,mBAAA;EACA,WAAA;EAMA,sDAAA;EACA,2DAAA;AAJF;AADE;EACE,aAAA;AAGJ;AAEE;EACE,eAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;AADJ;AAIE;EACE,eAAA;AAFJ;AAKE;EACE,mBAAA;EACA,iCAAA;EACA,eAAA;EACA,QAAA;EACA,kBAAA;EACA,YAAA;AAHJ;AAME;EACE,eAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AAJJ;AAOE;EACE,OAAA;AALJ;AAQE;EACE,QAAA;AANJ","sourcesContent":[":host {\n  background-color: var(--black-a9);\n  color: var(--white);\n  width: 100%;\n\n  .info {\n    padding: 12px;\n  }\n\n  /* stylelint-disable selector-max-compound-selectors */\n  /* stylelint-disable stylelint-plugins/no-font-properties */\n  ::ng-deep .info mat-icon {\n    font-size: 32px;\n    line-height: 32px;\n    height: 32px;\n    width: 32px;\n  }\n\n  img {\n    max-width: 100%;\n    max-height: 100%;\n  }\n\n  .close {\n    cursor: pointer;\n  }\n\n  .navigation {\n    align-items: center;\n    background-color: var(--black-a5);\n    cursor: pointer;\n    top: 50%;\n    position: absolute;\n    z-index: 500;\n  }\n\n  :host ::ng-deep .navigation mat-icon {\n    font-size: 48px;\n    line-height: 48px;\n    height: 48px;\n    width: 48px;\n  }\n\n  .navigation.left {\n    left: 0;\n  }\n\n  .navigation.right {\n    right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
