import { AfterContentChecked, Component, ContentChild, EventEmitter, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { WizardBaseTabComponent } from '../wizard-base-tab/wizard-base-tab.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'spx-wizard-tab',
    templateUrl: './wizard-tab.component.html',
    standalone: false
})
export class WizardTabComponent extends WizardBaseTabComponent implements AfterContentChecked, OnDestroy {
  @ViewChild(TemplateRef) template!: TemplateRef<unknown>;
  @ContentChild('wizardTab', { descendants: true }) baseTab?: WizardBaseTabComponent;
  @Output() completedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private completedSubscription?: Subscription;

  ngAfterContentChecked() {
    if (this.baseTab && this.baseTab.completed !== this.completed) {
      this.completed = this.baseTab.completed;
      this.completedChange.emit(this.completed);
    }
  }

  ngOnDestroy() {
    this.completedSubscription?.unsubscribe();
  }
}
