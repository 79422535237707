import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { ApiPrefix, Omit } from '../providers';

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor(
    @Inject(ApiPrefix) private prefix: string,
    @Inject(Omit) private omits: string[] = ['assets'],
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let prefix = this.prefix.endsWith('/') ? this.prefix : `${this.prefix}/`;

    if (this.omits?.some((omit) => req.url.startsWith(omit))) {
      prefix = '';
    }

    const request = req.clone({ url: prefix + req.url });
    return next.handle(request);
  }
}
