import { Component, ComponentFactoryResolver, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogDirective } from '../../directives/dialog.directive';
import { DialogData } from '../../model';

@Component({
    selector: 'spx-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    standalone: false
})
export class DialogComponent<T> implements OnInit {
  /**
   * Loading state of dialog component
   */
  public loading = false;

  /**
   * Dialog Directive for dynamic content projection
   */
  @ViewChild(DialogDirective, { static: true }) dialogDirective!: DialogDirective;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent<T>>,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) public data: DialogData<T>
  ) {}

  ngOnInit() {
    // if the component is set in the data, dynamically insert it at the ng-content of the dialog directive
    if (this.data.component) {
      const viewContainerRef = this.dialogDirective.viewContainerRef;
      viewContainerRef.clear();

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory<T>(this.data.component);
      viewContainerRef.createComponent(componentFactory);
    }
  }

  /**
   * Sets the loading state of the dialog
   * @param loading
   */
  public setLoading(loading: boolean): void {
    this.loading = loading;
  }

  /**
   * Closes the dialog
   */
  public closeDialog(): void {
    this.dialogRef.close();
  }
}
