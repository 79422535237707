import { Injector } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpHandler } from '@angular/common/http';

export function customHttpClientFactory(injector: Injector, additionalInterceptors: any[]): HttpClient {
  const httpHandler = injector.get(HttpHandler);
  const globalInterceptors = injector.get(HTTP_INTERCEPTORS, []);
  const interceptors = [...globalInterceptors, ...additionalInterceptors];
  const wrappedHandler = interceptors.reduceRight((next, interceptor) => new HttpInterceptorHandler(next, interceptor), httpHandler);

  return new HttpClient(wrappedHandler);
}

class HttpInterceptorHandler implements HttpHandler {
  constructor(
    private next: HttpHandler,
    private interceptor: any,
  ) {}

  handle(req: any) {
    return this.interceptor.intercept(req, this.next);
  }
}
