import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceService } from '../../../shared/services/device.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent {
  constructor(
    private readonly deviceService: DeviceService,
    private readonly router: Router,
  ) {}

  navigateToHomeWithDevice(): void {
    const deviceId = this.deviceService.getDeviceId();
    const route = deviceId ? `home/${deviceId}` : 'home';
    this.router.navigate([route]);
  }
}
