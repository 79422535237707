import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogAction, DialogService, NotificationService, NotificationType } from '@ird/ng-base';
import { Observable, Subscription, combineLatest, concat, map, of } from 'rxjs';
import { DocumentFileType, FileWithDetails } from '../../models';
import { UserService } from '../../services';

export interface DialogData {
  uploadType: DocumentFileType;
  uploadMethod: (fileDetails: FileWithDetails, type: DocumentFileType) => Observable<any>;
}

@Component({
    selector: 'shared-upload-dialog',
    templateUrl: './upload-dialog.component.html',
    styleUrls: ['./upload-dialog.component.scss'],
    standalone: false
})
export class UploadDialogComponent implements OnInit {
  // tab management
  public fileFormGroup: FormGroup;
  public file: File;
  public uploadedFileName: string | null = null;
  public fileType: string;

  readonly types: DocumentFileType[];

  constructor(
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      dialogData: DialogData;
      actions: DialogAction[];
    },
  ) {
    this.data.actions = [
      {
        type: 'mat-stroked-button',
        color: 'primary',
        text: 'UPLOAD_DIALOG_BUTTON_CLOSE',
        action: () => {
          this.dialogService.close();
          return Subscription.EMPTY;
        },
      },
      {
        type: 'mat-raised-button',
        color: 'primary',
        text: 'UPLOAD_DIALOG_BUTTON_UPLOAD',
        action: () => {
          const fileDetails = this.getFileDetails();
          const type = this.fileFormGroup.controls.type.value ?? this.types[0];
          return this.data.dialogData.uploadMethod(fileDetails, type).subscribe({
            next: this.handleSuccess,
            error: this.handleError,
          });
        },
        disable: () => {
          return this.disabled();
        },
      },
    ];

    const internalEmployee = this.userService.getUser().isInternal;
    const types = [DocumentFileType.USER, DocumentFileType.MANUFACTURER, DocumentFileType.GATED_DOCUMENT];
    if (internalEmployee) {
      types.push(DocumentFileType.INTERNAL);
    }
    this.types = types;
  }

  ngOnInit(): void {
    this.fileFormGroup = this.formBuilder.group({
      name: new FormControl(null, Validators.required),
      type: new FormControl(this.data.dialogData.uploadType ?? this.types[0], Validators.required),
      description: new FormControl(null),
      category: new FormControl(null),
      language: new FormControl(null),
    });
  }

  onFileChange(file: File) {
    this.file = file;
    if (file) {
      const lastIndex = file.name.lastIndexOf('.');
      if (!this.fileFormGroup.controls.name) {
        this.fileFormGroup.controls.name.setValue(file.name.substring(0, lastIndex));
      }
      this.fileType = file.name.substring(lastIndex + 1);
    } else {
      this.fileFormGroup.controls.name.setValue(null);
      this.fileType = '';
    }
  }

  /**
   * This function collects the streams of the formgroups and tab changes
   * and converts it to a "diabled" stream for the diabled button
   *
   * @returns a stream to control the disable state of the dialog button
   */
  private disabled(): Observable<boolean> {
    // file content tab disable stream
    const fileContentDisable = this.fileFormGroup.statusChanges.pipe(
      map((status) => {
        return !this.file;
      }),
    );

    // combines the streams to one. For "combineLatest" there is a initial value necessary, which will be provided by "concat"
    const combinedStream = combineLatest([concat(of(true), fileContentDisable)]);

    // map the stream to the desired desabled stream
    return combinedStream.pipe(map((disableStates) => disableStates[0]));
  }

  private getFileDetails(): FileWithDetails {
    const fileDetails: FileWithDetails = {
      file: this.file,
      details: {
        displayName: this.fileFormGroup.controls.name.value + '.' + this.fileType,
        description: this.fileFormGroup.controls.description.value,
        fileName: this.file.name,
        type: this.fileFormGroup.controls.type.value,
        metadata: {
          CATEGORY_TYPE: this.fileFormGroup.controls.category.value,
          DOCUMENT_TYPE: this.fileFormGroup.controls.type.value,
          LANG: this.fileFormGroup.controls.language.value,
        },
      },
    };
    return fileDetails;
  }

  /**
   * Handles file upload success
   *
   * @param file Uploaded file
   */
  private handleSuccess = (file: File): void => {
    this.dialogService.close(file);
    this.notificationService.showNotification({
      message: 'UPLOAD_DIALOG.UPLOAD_SUCCESS',
      type: NotificationType.Success,
    });
  };

  /**
   * Handles file upload error
   */
  private handleError = (): void => {
    this.notificationService.showNotification({
      message: 'UPLOAD_DIALOG.UPLOAD_ERROR',
      type: NotificationType.Error,
    });
  };
}
