import { AfterViewInit, Component, ContentChild, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'spx-form-field',
    templateUrl: './form-field.component.html',
    standalone: false
})
export class FormFieldComponent implements AfterViewInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChild(MatFormFieldControl) _control!: any;
  @ViewChild(MatFormField) _matFormField!: MatFormField;
  @ViewChild('dummyInput') private dummyInput!: ElementRef;
  @Input() name?: string;
  @Input() label = '';

  element!: HTMLElement;
  public fieldControl!: FormControl;

  constructor(private translateService: TranslateService) {}

  ngAfterViewInit() {
    this.element = this._control._elementRef.nativeElement;
    this.element.classList.add(
      'mat-mdc-input-element',
      'mat-mdc-form-field-input-control',
      'mdc-text-field__input',
      'cdk-text-field-autofill-monitored',
    );

    this.element.addEventListener('blur', this.onBlur);

    setTimeout(() => {
      this._matFormField._control = this._control;
      this.fieldControl = this._matFormField._control.ngControl?.control as FormControl;
      this.dummyInput.nativeElement.remove();

      this._matFormField.ngAfterContentInit();
      this._matFormField.ngAfterContentChecked();
      this._matFormField.ngAfterViewInit();
    });
  }

  get fieldName() {
    return this.name ? this.name : this.translateService.instant('FORM_FIELD.FIELD');
  }

  private onBlur = () => {
    this.element.removeEventListener('blur', this.onBlur);
    this.element.click();
    this.element.blur();
  };
}
