// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  align-items: center;
  display: flex;
  justify-content: center;
}

:host(.display) {
  height: 100%;
  width: 100%;
}

:host(.overlay.display) {
  /* stylelint-disable-next-line stylelint-plugins/no-hardcoded-colors */
  background: rgba(1, 1, 1, 0.3);
  left: 0;
  position: absolute;
  top: 0;
  z-index: 200;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/base/spinner/spinner.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;AACF;;AAEA;EACE,sEAAA;EACA,8BAAA;EACA,OAAA;EACA,kBAAA;EACA,MAAA;EACA,YAAA;AACF","sourcesContent":[":host {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n}\n\n:host(.display) {\n  height: 100%;\n  width: 100%;\n}\n\n:host(.overlay.display) {\n  /* stylelint-disable-next-line stylelint-plugins/no-hardcoded-colors */\n  background: rgba(1, 1, 1, 0.3);\n  left: 0;\n  position: absolute;\n  top: 0;\n  z-index: 200;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
