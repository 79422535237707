import { AfterContentInit, Directive, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { instanceOfPaginatedDataSource } from '../../../collections';
import { TableComponent } from '../../components/table/table.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'spx-table[paginator]',
  standalone: false,
})
export class PaginatorDirective<T> implements AfterContentInit {
  @Input() set paginator(paginator: MatPaginator) {
    this._paginator = paginator;
  }

  private _paginator!: MatPaginator;

  constructor(private table: TableComponent<T>) {}

  ngAfterContentInit(): void {
    if (instanceOfPaginatedDataSource(this.table.dataSource)) {
      this.table.dataSource.setPaginator(this._paginator);
    }
  }
}
