import { PagedData } from '../paged-data';
import { Promisified } from './async-mapper-factory';
import { Omitter } from './generic-map';
import { DaoType, DefaultSyncDtoMapper, DtoType, SyncDtoMapper } from './sync-dto-mapper';

export interface DtoMapper<Dao extends DaoType, Dto extends DtoType> extends Promisified<SyncDtoMapper<Dao, Dto>> {
  sync: SyncDtoMapper<Dao, Dto>;
}

export abstract class DefaultDtoMapper<Dao extends DaoType, Dto extends DtoType> implements DtoMapper<Dao, Dto> {
  public sync: SyncDtoMapper<Dao, Dto>;

  protected abstract getDaoSample(): Dao;
  protected abstract getDtoSample(): Dto;

  constructor() {
    this.sync = this.createSyncMapper();
  }

  public createSyncMapper(): SyncDtoMapper<Dao, Dto> {
    return new DefaultSyncDtoMapper<Dao, Dto>(this.getDtoSample(), this.getDaoSample());
  }

  public fromDao(dao: Promise<Dao>, toAdd: Omitter<Dao, Dto>): Promise<Dto>;
  public fromDao(dao: Promise<Dao | null>, toAdd: Omitter<Dao, Dto>): Promise<Dto | null>;
  public fromDao(dao: Promise<Dao | null>, toAdd: Omitter<Dao, Dto>): Promise<Dto | null> {
    return dao.then((awaitedDao) => {
      return this.sync.fromDao(awaitedDao, toAdd);
    });
  }

  public fromNullableDao(dao: Promise<Dao | null>, toAdd: Omitter<Dao, Dto>): Promise<Dto | null> {
    return dao.then((awaitedDao) => {
      return this.sync.fromDao(awaitedDao, toAdd);
    });
  }

  public toDao(dto: Promise<Dto>, toAdd: Omitter<Dto, Dao>): Promise<Dao>;
  public toDao(dto: Promise<Dto | null>, toAdd: Omitter<Dto, Dao>): Promise<Dao | null>;
  public toDao(dto: Promise<Dto | null>, toAdd: Omitter<Dto, Dao>): Promise<Dao | null> {
    return dto.then((awaitedDto) => {
      return this.sync.toDao(awaitedDto, toAdd);
    });
  }

  public fromPagedDao(pagedData: Promise<PagedData<Dao>>, toAdd: Omitter<Dao, Dto>): Promise<PagedData<Dto>> {
    return pagedData.then((awaitedPagedData) => {
      return this.sync.fromPagedDao(awaitedPagedData, toAdd);
    });
  }
}
