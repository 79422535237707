import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'shared-export-csv-smackbar',
    templateUrl: './export-csv-snackbar.component.html',
    styleUrls: ['./export-csv-snackbar.component.scss'],
    standalone: false
})
export class ExportCSVSnackbarComponent {
  constructor(private snackBar: MatSnackBar) {}

  cancelSnackbar(): void {
    this.snackBar.dismiss();
  }
}
