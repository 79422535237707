// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .header-section {
  display: flex;
}
:host .warranty-header-text {
  padding-left: 10px;
  padding-top: 20px;
}
:host .add-icon {
  font-size: x-large;
}
:host .warranty-text {
  white-space: normal;
  font-size: 14px;
  font-weight: 400;
}
:host .warranty-list-formatting {
  height: auto;
}
:host .extended-warranty-text {
  font-size: 18px;
  font-weight: 400;
  padding-top: 5px;
}
:host .warranty-points > mat-list ::ng-deep .mdc-list-item__start {
  margin-right: 20px;
}
:host .warranty-points > mat-list ::ng-deep .mat-mdc-list-item.mdc-list-item--with-three-lines .mat-mdc-list-item-unscoped-content.mdc-list-item__secondary-text {
  display: inline-block;
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./apps/scs-service-point/src/app/shared/components/warranty-terms/warranty-terms.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAGE;EACE,kBAAA;EACA,iBAAA;AADJ;AAIE;EACE,kBAAA;AAFJ;AAKE;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;AAHJ;AAME;EACE,YAAA;AAJJ;AAOE;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;AALJ;AAQE;EACE,kBAAA;AANJ;AASE;EAKE,qBAAA;EACA,iBAAA;AAXJ","sourcesContent":[":host {\n  .header-section {\n    display: flex;\n  }\n\n  .warranty-header-text {\n    padding-left: 10px;\n    padding-top: 20px;\n  }\n\n  .add-icon {\n    font-size: x-large;\n  }\n\n  .warranty-text {\n    white-space: normal;\n    font-size: 14px;\n    font-weight: 400;\n  }\n\n  .warranty-list-formatting {\n    height: auto;\n  }\n\n  .extended-warranty-text {\n    font-size: 18px;\n    font-weight: 400;\n    padding-top: 5px;\n  }\n\n  .warranty-points > mat-list ::ng-deep .mdc-list-item__start {\n    margin-right: 20px;\n  }\n\n  .warranty-points\n    > mat-list\n    ::ng-deep\n    .mat-mdc-list-item.mdc-list-item--with-three-lines\n    .mat-mdc-list-item-unscoped-content.mdc-list-item__secondary-text {\n    display: inline-block;\n    padding-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
