import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { DateRange } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { Observable, ReplaySubject, Subscription, of } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { SearchConfig } from './models/search-config.model';
import { SearchParameters } from './models/search-parameters.model';
import { SearchOptionDirective } from './search-option.directive';

@Component({
  selector: 'shared-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  standalone: false,
})
export class SearchBarComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  searchControl = new FormControl();
  filterValue: string | string[] = '';
  sortValue: string | undefined = '';
  dateRange: DateRange<Moment> | null;

  public filteredOptions!: Observable<Array<any>>;

  private parametersSubscription!: Subscription;
  private date!: {
    from: { key: string; value: string };
    to: { key: string; value: string };
  };

  @Input() initialData: any;
  @Input() config!: SearchConfig;
  @Input() initialParametersChange: ReplaySubject<SearchParameters> = new ReplaySubject<SearchParameters>();
  @Input() autofocusEnabled = true;
  @Input() searchString = '';

  @Output() parametersChanged: EventEmitter<SearchParameters> = new EventEmitter<SearchParameters>();
  @Output() searchInputChanged: EventEmitter<string> = new EventEmitter<string>();

  @ContentChild(SearchOptionDirective, { static: true }) optionTemplate?: SearchOptionDirective;

  @ViewChild('toggleGroup') private toggleGroup!: MatButtonToggleGroup;

  ngOnInit(): void {
    if (!this.config) {
      return;
    }

    if (this.config.search?.value) {
      this.searchControl.setValue(this.config.search?.value);
    }

    this.sortValue = this.config.sortOptions?.[0].value;

    if (this.config.filter?.value) {
      this.filterValue = this.config.filter.value;
    }

    if (this.config.search?.autocomplete) {
      this.initAutocomplete();
    } else if (this.parametersChanged) {
      this.searchControl.valueChanges
        .pipe(
          tap((value) => {
            this.searchInputChanged.next(value);
          }),
          debounceTime(400),
        )
        .subscribe(() => {
          this.computeParameters();
        });
    }

    if (this.initialParametersChange) {
      this.parametersSubscription = this.initialParametersChange.subscribe((params) => {
        if (params.filter && this.toggleGroup) {
          if (this.config?.filter?.multiple) {
            this.toggleGroup.value = params.filter.map((f) => f.value);
          } else {
            this.toggleGroup.value = params.filter[0].value;
          }
        } else if (params.search) {
          this.searchControl.setValue(params.search, {
            emitEvent: true,
          });
        }
      });
    }

    if (this.config.dateFilter) {
      if (this.config.dateFilter.startValue && this.config.dateFilter.endValue) {
        const start = moment(this.config.dateFilter.startValue / 1000000);
        const end = moment(this.config.dateFilter.endValue / 1000000);
        this.dateRange = new DateRange(start, end);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchString) {
      this.searchControl.setValue(changes.searchString.currentValue);
    }
    if (changes.initialData?.currentValue !== changes.initialData?.previousValue && this.toggleGroup) {
      this.toggleGroup.value = this.filterValue;
    }
  }

  ngAfterViewInit(): void {
    if (this.config.filter?.value) {
      this.toggleGroup.value = this.filterValue;
    }
  }

  ngOnDestroy() {
    if (this.parametersSubscription) this.parametersSubscription.unsubscribe();
  }

  initAutocomplete(): void {
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      tap((value) => {
        this.searchInputChanged.next(value);
      }),
      debounceTime(500),
      switchMap((value) => {
        return this.config.search?.dataLoader ? this.config.search?.dataLoader(value, 0, 50) : of([]);
      }),
    );
  }

  getElementValue(element: any) {
    if (this.config?.search && typeof this.config.search.variableName === 'string') {
      return element[this.config.search.variableName];
    } else if (this.config?.search?.variableName && Array.isArray(this.config?.search?.variableName)) {
      return element[
        this.config.search.variableName.find((item) => {
          return element[item]?.toLowerCase().includes(this.searchControl.value?.toLowerCase());
        })
      ];
    }
  }

  sort(sort: string) {
    this.sortValue = sort;
    this.computeParameters();
  }

  toggleFilter() {
    const toggleValue = this.toggleGroup.value;
    if (toggleValue) {
      if (toggleValue === this.filterValue) {
        this.toggleGroup.value = '';
        this.filterValue = null;
      } else {
        this.toggleGroup.value = toggleValue;
        this.filterValue = toggleValue;
      }
    }
    this.computeParameters();
  }

  clearSearchField(): void {
    this.searchControl.reset(undefined, { emitEvent: true });

    if (this.config?.search?.autocomplete) {
      this.computeParameters();
    }
  }

  computeParameters(): void {
    const sort = this.sortValue ? this.sortValue.split('-') : [];
    const sortKey = sort[0] ?? '';
    const sortDirection = sort[1] ?? '';

    const parameters: SearchParameters = {
      search: this.searchControl.value,

      sort: {
        dir: sortDirection,
        key: sortKey,
      },
    };

    if (this.config?.filter?.multiple) {
      parameters.filter = (Array.isArray(this.filterValue) ? this.filterValue : [this.filterValue]).map((value) => {
        return {
          value,
        };
      });
    } else {
      parameters.filter = [
        {
          value: this.filterValue as string,
        },
      ];
    }

    if (this.date?.to) {
      parameters.filter.push(this.date.to);
    }

    if (this.date?.from) {
      parameters.filter.push(this.date.from);
    }

    this.parametersChanged.next(parameters);
  }

  chooseAutocompleteElement(search: string): void {
    this.searchControl.setValue(search);
    this.computeParameters();
  }

  dateRangeChanged(dateRange: DateRange<Moment>): void {
    this.date = {
      from: { key: 'from', value: String(dateRange.start.valueOf() * 1000000) },
      to: { key: 'to', value: String(dateRange.end.valueOf() * 1000000) },
    };
    this.computeParameters();
  }
}
