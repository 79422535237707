import { DeepPath, GeneratedObject, I18n, NestedObject, SubTree } from './i18n.utils';

export abstract class BaseFeatureTranslationService<T extends NestedObject, P extends DeepPath<T>> {
  private featureTranslations: SubTree<GeneratedObject<T>, P>;

  protected abstract getTranslationJSON(): T;
  protected abstract getFeaturePath(): P;

  constructor() {
    this.featureTranslations = this.extractFeatureTranslations();
  }

  private extractFeatureTranslations(): SubTree<GeneratedObject<T>, P> {
    const enTranslations = this.getTranslationJSON();
    const featurePath = this.getFeaturePath();
    const generatedKeyPaths = I18n.generateKeyPath(enTranslations);

    return featurePath.split('.').reduce<GeneratedObject<T>>((obj, key) => {
      return obj[key] as unknown as GeneratedObject<T>;
    }, generatedKeyPaths) as SubTree<GeneratedObject<T>, P>;
  }

  public getTranslations(): SubTree<GeneratedObject<T>, P> {
    return this.featureTranslations;
  }
}
