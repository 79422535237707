import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[sharedNavTab]',
    standalone: false
})
export class NavTabDirective {
  @Input() name: string;
  @Input() path: string;
  @Input() icon?: string;
}
