import { DaoType, DtoType, Omitter } from '../mapper';
import { DtoMapper } from '../mapper/dto-mapper';
import { PagedData } from '../paged-data';
import { createSearchableSortableHttpParam } from '../searchable-sortable-http-param';
import { UUID } from '../uuid';
import { CrudGetAllDto } from './crud-get-all-dto';
import { CrudRepository } from './crud.repository';

export abstract class CrudService<Dao extends DaoType, Dto extends DtoType> {
  protected constructor(
    protected readonly repository: CrudRepository<Dao>,
    protected readonly dtoMapper: DtoMapper<Dao, Dto>,
    protected readonly toAdd: Omitter<Dao, Dto>,
  ) {}

  async getAll(dto: CrudGetAllDto): Promise<PagedData<Dto>> {
    const { query, pageable } = createSearchableSortableHttpParam(dto);
    return this.dtoMapper.fromPagedDao(this.repository.getAll({ query, pageable }), this.toAdd);
  }

  async create(resource: Dao): Promise<Dto | null> {
    return this.dtoMapper.fromDao(this.repository.create({ resource }), this.toAdd);
  }

  async update(resource: Dao): Promise<Dto | null> {
    return this.dtoMapper.fromDao(this.repository.update({ resource }), this.toAdd);
  }

  async delete(id: UUID): Promise<UUID | null> {
    return await this.repository.delete({ id });
  }

  async getById(id: UUID): Promise<Dto | null> {
    return this.dtoMapper.fromDao(this.repository.getById({ id }), this.toAdd);
  }

  async getByIds(ids: UUID[]): Promise<Awaited<Dto | null>[]> {
    return Promise.all(ids.map((id: UUID) => this.getById(id)));
  }
}
