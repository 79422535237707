import { PricespiderConfig, PricespiderConfigLoader } from '@ird/ng-tools';
import { filter, from, map, Observable, switchMap } from 'rxjs';
import { FeatureFlag, ServicePointFeatures } from '@deprecated/api-interfaces';
import { UnleashService } from '../core/services/unleash.service';
import { TenantConfigService } from '../shared/services/tenant-config.service';
import { TenantService } from '@ird/ng-common';

export class PricespiderFactory extends PricespiderConfigLoader {
  constructor(
    private unleashService: UnleashService,
    private tenantConfigService: TenantConfigService,
    private tenantService: TenantService,
  ) {
    super();
  }

  getConfig(): Observable<PricespiderConfig> {
    return this.tenantService.changeSettingsEvent().pipe(
      switchMap(() => {
        const apiKey = this.tenantConfigService.getServicePointFeatures()?.[ServicePointFeatures.PRICE_SPIDER_API_KEY];
        return from(this.unleashService.isEnabled(FeatureFlag.PRICESPIDER, false)).pipe(
          filter((enabled) => enabled),
          map(() => ({
            apiKey,
            country: 'US',
            language: 'en',
          })),
        );
      }),
    );
  }
}
