// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spx-progress-bar-info {
  margin-top: 1em;
}

.spx-progress-bar-error {
  color: var(--warn-text);
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/progress-bar/components/progress-bar.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,uBAAA;AACF","sourcesContent":[".spx-progress-bar-info {\n  margin-top: 1em;\n}\n\n.spx-progress-bar-error {\n  color: var(--warn-text);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
