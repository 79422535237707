import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Host,
  HostBinding,
  InjectionToken,
  Input,
  Output,
  QueryList,
  TemplateRef,
  TrackByFunction,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatColumnDef, MatNoDataRow, MatTable } from '@angular/material/table';
import { SpxDataSource, instanceOfFilteredDataSource, instanceOfSortedDataSource } from '../../../collections';
import { DragDirective, DropDirective, SelectionDirective } from '../../directives';
import { SpxRowDirective } from '../../directives/row/row.directive';
import { Column, ColumnConfig, TableConfig } from '../../model';

export const TABLE_INJECTION_TOKEN = new InjectionToken<string>('spx-table');

@Component({
  selector: 'spx-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [
    MatSort,
    {
      provide: TABLE_INJECTION_TOKEN,
      useExisting: TableComponent,
    },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  standalone: false,
})
export class TableComponent<T> implements AfterContentInit {
  @ViewChild(MatTable, { static: true }) matTable!: MatTable<T>;
  @ViewChildren(SpxRowDirective) rowDefs!: QueryList<SpxRowDirective<T>>;
  @ContentChildren(MatColumnDef) columnDefs!: QueryList<MatColumnDef>;
  @ContentChild(MatNoDataRow) noDataRow!: MatNoDataRow;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChild('expandedRow') expandedTemplate: TemplateRef<any> | null = null;
  @Input() dataSource!: SpxDataSource<T>;
  @Input() displayedColumns: string[] = [];
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('trackBy') trackByInput?: TrackByFunction<T>;
  @Output() rowClicked = new EventEmitter<T>();
  @Output() rowExpanded = new EventEmitter<T>();
  public tableConfig: TableConfig = {} as TableConfig;
  public selectionDirective?: SelectionDirective<T>;
  public dragDirective?: DragDirective<T>;
  public dropDirective?: DropDirective<T>;
  public expandedRows: T[] = [];

  constructor(@Host() private matSort: MatSort) {}

  @HostBinding('class.spx-expandable') get isExpandable() {
    return !!this.expandedTemplate;
  }

  @Input() set config(config: TableConfig) {
    this.tableConfig = config;
    config.columns.forEach((columnConfig: ColumnConfig) => this.addDisplayedColumn(columnConfig.name));
  }

  private _multiExpansion = false;

  get multiExpansion(): boolean {
    return this._multiExpansion;
  }

  set multiExpansion(multiExpansion: boolean) {
    this._multiExpansion = multiExpansion;
  }

  ngAfterContentInit(): void {
    if (instanceOfSortedDataSource(this.dataSource)) {
      this.dataSource.setSort(this.matSort);
      this.matSort.ngOnInit();
    }
    if (this.isExpandable) {
      this.displayedColumns.unshift('spxExpandableArrow');
    }
  }

  public addColumn(column: Column): void {
    this.addDisplayedColumn(column.name);
    this.matTable.addColumnDef(column.columnDef);
  }

  public removeColumn(column: Column): void {
    this.removeDisplayedColumn(column.name);
    this.matTable.removeColumnDef(column.columnDef);
  }

  public onRowClicked(row: T): void {
    if (this.expandedTemplate) {
      const rowIndex = this.expandedRows.findIndex((item) => item === row);

      if (rowIndex !== -1) {
        this.expandedRows.splice(rowIndex, 1);
      } else {
        if (this._multiExpansion) {
          this.expandedRows.push(row);
        } else {
          this.expandedRows.splice(0, 1, row);
        }
        this.rowExpanded.emit(row);
      }
    }

    this.rowClicked.emit(row);
  }

  public isRowExpanded(row: T): boolean {
    return this.expandedRows.some((item) => item === row);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public trackBy(index: number, element: any) {
    if (this.trackByInput) {
      return this.trackByInput(index, element);
    }
    return element?.id || element?.name || index;
  }

  public enableSort(columnConfig: ColumnConfig): boolean {
    return instanceOfFilteredDataSource(this.dataSource) && !!columnConfig.sort;
  }

  public getSortProp(columnConfig: ColumnConfig): string {
    return columnConfig.sortProperty ?? columnConfig?.name;
  }

  public addDisplayedColumn(name: string): void {
    const index = this.displayedColumns.findIndex((displayedColumn: string) => displayedColumn === name);
    if (index === -1) {
      //This is a hack to get the an ordered list of columns from the DOM
      const orderedNames = Array.from(this.matTable['_columnDefsByName']?.keys());

      this.displayedColumns.push(name);
      this.displayedColumns.sort((a, b) => {
        return orderedNames.indexOf(a) - orderedNames.indexOf(b);
      });
    }
  }

  public removeDisplayedColumn(name: string): void {
    const index = this.displayedColumns.findIndex((displayedColumn: string) => displayedColumn === name);
    if (index !== -1) {
      this.displayedColumns.splice(index, 1);
    }
  }
}
