import { Directive, TemplateRef } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'ng-template:not([spxColumnHeader])',
    standalone: false
})
export class ColumnTemplateDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}
