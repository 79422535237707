import { Directive, TemplateRef } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[branding]',
    standalone: false
})
export class BrandingDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}
