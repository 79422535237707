import { DOCUMENT } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider, inject, provideAppInitializer } from '@angular/core';
import { pricespiderInitializer } from './pricespider.initializer';
import { PricespiderConfigFakeLoader, PricespiderConfigLoader } from './providers';

@NgModule()
export class PricespiderModule {
  static forRoot(configLoader: Provider): ModuleWithProviders<PricespiderModule> {
    return {
      ngModule: PricespiderModule,
      providers: [
        configLoader || { provide: PricespiderConfigLoader, useClass: PricespiderConfigFakeLoader },
        provideAppInitializer(() => {
        const initializerFn = (pricespiderInitializer)(inject(DOCUMENT), inject(PricespiderConfigLoader));
        return initializerFn();
      }),
      ],
    };
  }
}
