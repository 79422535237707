import { Component, Input } from '@angular/core';

export interface Breadcrumb {
  name: string;
  navigate?: string[];
  icon?: string;
  onClick?: (event: MouseEvent) => void;
  queryParams?: { [key: string]: any };
}

@Component({
    selector: 'spx-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    standalone: false
})
export class BreadcrumbsComponent {
  @Input() root: Breadcrumb | null = {
    name: '',
    navigate: ['/'],
    icon: 'icon-home',
  };
  @Input() breadcrumbs!: Breadcrumb[];
}
