// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  position: relative;
}

table {
  width: 100%;
}

.no-data {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

:host.spx-selectable table tr.spx-row:hover,
:host.spx-expandable table tr.spx-row:hover {
  cursor: pointer;
}

.spx-selection-checkbox-cell {
  padding-right: 16px;
  width: 24px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  pointer-events: none;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/table/components/virtualized-table/virtualized-table.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,OAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;EACA,WAAA;AACF;;AAEA;;EAEE,eAAA;AACF;;AAEA;EACE,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,eAAA;EACA,oBAAA;EACA,WAAA;AACF","sourcesContent":[":host {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  overflow: auto;\n  position: relative;\n}\n\ntable {\n  width: 100%;\n}\n\n.no-data {\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  left: 0;\n  position: absolute;\n  top: 0;\n  width: 100%;\n  z-index: 10;\n}\n\n:host.spx-selectable table tr.spx-row:hover,\n:host.spx-expandable table tr.spx-row:hover {\n  cursor: pointer;\n}\n\n.spx-selection-checkbox-cell {\n  padding-right: 16px;\n  width: 24px;\n}\n\n.loading-spinner {\n  display: flex;\n  justify-content: center;\n  padding: 1rem 0;\n  pointer-events: none;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
