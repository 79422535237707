import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, ViewContainerRef } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { delay } from 'rxjs/operators';
import { NotificationService, NotificationType } from '../../notification';

@Directive({
    selector: '[spxChart]',
    standalone: false
})
export class ChartDirective {
  @Input() chartRef?: ElementRef;
  @Input() chart?: boolean;
  @Input() scrolling = false;
  @Input() xAxisExtremes?: { min: number; max: number };
  @Input() viewContainerRef?: ViewContainerRef;

  @Output() timeRangeChange = new EventEmitter<{ from: number; to: number }>();
  @Output() scrollingChange = new EventEmitter<boolean>();
  @Output() snackbarDismissed = new EventEmitter<void>();
  @Output() hoverChart = new EventEmitter<boolean>();

  public snackbarRef?: MatSnackBarRef<any>;

  private lastMouseEvent?: MouseEvent;
  private mouseDown = false;
  private scrollTimeout?: ReturnType<typeof setTimeout>;

  constructor(
    private hostContainerRef: ViewContainerRef,
    private notificationService: NotificationService,
  ) {
    document.addEventListener(
      'wheel',
      (event) => {
        if (event.ctrlKey && this.ready && event.target) {
          if ((event.target as Node).contains(this.hostContainerRef.element.nativeElement)) {
            event.preventDefault();
          }
        }
      },
      {
        passive: false,
      },
    );
  }

  private get ready(): boolean {
    return !!(this.chart && this.xAxisExtremes?.min && this.xAxisExtremes?.max);
  }

  @HostListener('mouseup')
  public onMouseup(): void {
    this.mouseDown = false;
    if (this.chartRef?.nativeElement) {
      this.changeGlobalZoomCursor(true);
      this.chartRef.nativeElement.children[0].children[0].style.cursor = '';
    }
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.mouseDown && this.ready && event.shiftKey && this.lastMouseEvent) {
      const diff = event.clientX - this.lastMouseEvent.clientX;
      if (Math.abs(diff) > 10) {
        this.mouseTimeRangeChange(diff, 'move');
        this.lastMouseEvent = event;
      }
    }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.mouseDown = true;
    this.lastMouseEvent = event;
    if (this.chartRef?.nativeElement) {
    this.chartRef.nativeElement.children[0].children[0].style.cursor = 'crosshair';
    }
  }

  @HostListener('wheel', ['$event'])
  onMouseWheel(event: WheelEvent) {
    if (event.ctrlKey && this.snackbarRef) {
      this.snackbarRef.dismiss();
    }

    if (event.ctrlKey && this.ready) {
      event.preventDefault();
      this.mouseTimeRangeChange(event.deltaY);
    } else if (!event.ctrlKey && !this.snackbarRef) {
      this.displayHintSnackbar();
    }

    this.scrollingChange.emit(true);
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      this.scrollingChange.emit(false);
    }, 100);
  }

  @HostListener('mouseenter', ['$event'])
  @HostListener('mouseleave', ['$event'])
  public onMouseEnterOrLeave(event: MouseEvent): void {
    const { shiftKey, type } = event;

    if (this.ready) {
      this.changeGlobalZoomCursor(type === 'mouseenter' && !shiftKey);
    }
  }

  @HostListener('window:keydown', ['$event'])
  @HostListener('window:keyup', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    const { shiftKey } = event;

    if (this.chartRef?.nativeElement) {
      this.changeGlobalZoomCursor(!shiftKey);
      this.chartRef.nativeElement.children[0].children[0].style.cursor = shiftKey ? 'move' : '';
    }
  }

  public changeGlobalZoomCursor(dataZoomSelectActive: boolean): void {
    this.hoverChart.emit(dataZoomSelectActive);
  }

  public mouseTimeRangeChange(difference: number, type: 'move' | 'zoom' = 'zoom'): void {
    if (!this.xAxisExtremes || Number.isNaN(this.xAxisExtremes.min) || Number.isNaN(this.xAxisExtremes.max)) {
      return;
    }

    let from = this.xAxisExtremes?.min;
    let to = this.xAxisExtremes?.max;

    const timeDifference = to - from;
    let timeModification: number;
    const divider = type === 'move' ? 20 : 4;

    if (difference < 0) {
      timeModification = Math.round(timeDifference / divider);
    } else {
      timeModification = -Math.round(timeDifference / divider);
    }

    if (type === 'move') {
      from += timeModification;
      to += timeModification;
    } else {
      from += timeModification;
      to = to - timeModification;
    }

    if (new Date().valueOf() < to) {
      to = new Date().valueOf();
    }

    this.timeRangeChange.emit({ from, to });
  }

  private displayHintSnackbar(): void {
    this.snackbarDismissed.next();
    this.snackbarRef = this.notificationService.showNotification(
      {
        message: 'SPX.CHART.KEYPRESS_HINT',
        type: NotificationType.Primary,
      },
      { viewContainerRef: this.viewContainerRef },
    );
    this.snackbarRef
      ?.afterDismissed()
      .pipe(delay(5000))
      .subscribe(() => {
        this.snackbarDismissed.next();
        this.snackbarRef = undefined;
      });
  }
}
