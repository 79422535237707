// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-title {
  margin-left: 1rem;
}

.logo {
  content: var(--logo-url);
  height: 25px;
  cursor: pointer;
}

.logo::before {
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  height: 30px;
  width: 117px;
}`, "",{"version":3,"sources":["webpack://./apps/scs-service-point/src/app/layout/components/header/header.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,wBAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,4BAAA;EACA,wBAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;EACA,YAAA;AACF","sourcesContent":[".page-title {\n  margin-left: 1rem;\n}\n\n.logo {\n  content: var(--logo-url);\n  height: 25px;\n  cursor: pointer;\n}\n\n.logo::before {\n  background-repeat: no-repeat;\n  background-size: contain;\n  content: '';\n  display: block;\n  height: 30px;\n  width: 117px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
