export class ObjectBuilder<Target> {
  public new(): IWith<Target, unknown> {
    return new Builder<Target, unknown>({});
  }
}

export interface IWith<Target, Supplied> {
  with<T extends Omit<Target, keyof Supplied>, K extends keyof T>(
    key: K,
    value: T[K],
  ): keyof Omit<Omit<Target, keyof Supplied>, K> extends never ? IBuild<Target> : IWith<Target, Supplied & Pick<T, K>>;
}

interface IBuild<Target> {
  build(): Target;
}

class Builder<Target, Supplied> implements IBuild<Target>, IWith<Target, Supplied> {
  constructor(private target: Partial<Target>) {}

  with<T extends Omit<Target, keyof Supplied>, K extends keyof T>(key: K, value: T[K]) {
    const target: Partial<Target> = { ...this.target, [key]: value };

    return new Builder<Target, Supplied & Pick<T, K>>(target);
  }

  build() {
    return this.target as Target;
  }
}

// EXAMPLE
// export const sensorTypeDtoIBuilder = new ObjectBuilder<SensorTypeDto>()
//   .new()
//   .with('id', null)
//   .with('name', null)
//   .with('type', '')
//   .with('iconImageFile', '')
//   .with('color', createRandomColor())
//   .with('tenantId', '')
//   .with('valueType', ValueType.TEMPERATURE)
//   .with('metadata', null)
//   .with('unit', '°C')
//   .with('precision', 0);
