// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  height: 100%;
  overflow: hidden;
}
:host shared-search-bar {
  margin: 24px 24px 0;
}
:host mat-card {
  margin: 24px;
  overflow-y: hidden;
}
:host mat-card-content {
  height: 100%;
}
:host spx-table {
  overflow-y: auto;
}
:host .table-scroll {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./libs/shared/ng/common/src/lib/components/generic-admin-page/generic-admin-page.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;EACA,gBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,YAAA;EACA,kBAAA;AAAJ;AAGE;EACE,YAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAKE;EACE,YAAA;AAHJ","sourcesContent":[":host {\n  display: block;\n  height: 100%;\n  overflow: hidden;\n\n  shared-search-bar {\n    margin: 24px 24px 0;\n  }\n\n  mat-card {\n    margin: 24px;\n    overflow-y: hidden;\n  }\n\n  mat-card-content {\n    height: 100%;\n  }\n\n  spx-table {\n    overflow-y: auto;\n  }\n\n  .table-scroll {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
