import { Component, Input } from '@angular/core';

@Component({
    template: '',
    standalone: false
})
export class WizardBaseTabComponent {
  @Input() completed = false;
  @Input() label!: string;
  @Input() key!: string;
  @Input() disabled = false;
}
