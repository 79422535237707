import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileRepositoryUi {
  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private config: any,
  ) {}

  getTenantImage(fileId: string): string {
    return this.config.ui.tenant.logo.get.replace('{fileId}', fileId);
  }

  getTenantDefaultDeviceImage(fileId: string): string {
    return this.config.ui.tenant.defaultDeviceImage.get.replace('{fileId}', fileId);
  }

  getTenantImageData(fileId: string): Observable<HttpResponse<Blob>> {
    const path = this.config.ui?.tenant?.logo?.get.replace('{fileId}', fileId);
    return this.getFileAsBlob(path);
  }

  getTenantDefaultDeviceImageData(fileId: string): Observable<HttpResponse<Blob>> {
    const path = this.config.ui.tenant.defaultDeviceImage.get.replace('{fileId}', fileId);
    return this.getFileAsBlob(path);
  }

  getTenantDefaultEmailImage(fileId: string): string {
    return this.config.ui.tenant.defaultEmailImage.get.replace('{fileId}', fileId);
  }

  getTenantDefaultEmailImageData(fileId: string): Observable<HttpResponse<Blob>> {
    const path = this.config.ui.tenant.defaultEmailImage.get.replace('{fileId}', fileId);
    return this.getFileAsBlob(path);
  }

  private getFileAsBlob(path: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(path, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
