import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { IMAGE_SLIDER_TOKEN } from '../../services';

@Component({
    selector: 'shared-image-slider',
    templateUrl: './image-slider.component.html',
    styleUrls: ['./image-slider.component.scss'],
    standalone: false
})
export class ImageSliderComponent {
  @Output() panelClose: EventEmitter<void> = new EventEmitter();

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  imagePaths: string[] = [];
  currentIndex = 0;

  constructor(@Inject(IMAGE_SLIDER_TOKEN) private componentData: { imagePaths: string[]; startIndex: number }) {
    this.imagePaths = this.componentData.imagePaths;
    this.currentIndex = this.componentData.startIndex ?? 0;
  }

  previousImage(): void {
    if (this.currentIndex === 0) {
      this.currentIndex = this.imagePaths.length - 1;
    } else {
      this.currentIndex--;
    }
  }

  nextImage(): void {
    if (this.currentIndex === this.imagePaths.length - 1) {
      this.currentIndex = 0;
    } else {
      this.currentIndex++;
    }
  }

  swipe(event: TouchEvent, when: string): void {
    const coord: [number, number] = [event.changedTouches[0].clientX, event.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      const directionAbsX = Math.abs(direction[0]);
      const directionAbsY = Math.abs(direction[1] * 3);
      if (duration < 1000 && directionAbsX > 30 && directionAbsX > directionAbsY) {
        if (direction[0] < 0) {
          this.nextImage();
        } else {
          this.previousImage();
        }
      }
    }
  }

  close(): void {
    this.panelClose.emit();
  }
}
