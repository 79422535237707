import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, catchError, debounceTime, finalize, map, of, startWith, switchMap } from 'rxjs';
import { DeviceModel, Pageable } from '../../models';
import { AllowedPipe } from '../../pipes/allowed.pipe';
import { DeviceModelService } from '../../services';

@Component({
    selector: 'shared-model-autocomplete',
    templateUrl: './model-autocomplete.component.html',
    styleUrls: ['./model-autocomplete.component.scss'],
    standalone: false
})
export class ModelAutocompleteComponent implements OnInit {
  @Input() title: string;
  @Input() enabledRole: string;
  @Input() deviceId: string;
  @Input() categoryName = '';
  @Input() modelName: string;
  @Input() modelId: string;
  @Output() modelNameChange = new EventEmitter<string>();
  @Output() modelIdChange = new EventEmitter<string>();
  @Output() modelError = new EventEmitter<boolean>();

  private model: DeviceModel;
  private emptyDeviceModel = {
    category: null,
    description: '',
    id: '',
    metadata: {},
    modelDetails: [],
    name: '',
    tenantId: '',
    image_id: '',
    dashboardTemplateConfigId: '',
  };

  public modelControl = new FormControl<DeviceModel>(this.emptyDeviceModel);
  public filteredModels: Observable<DeviceModel[]>;
  public loading = false;
  pageable = {
    pageNumber: 0,
    pageSize: 100,
  } as Pageable;

  constructor(
    private deviceModelService: DeviceModelService,
    private allowed: AllowedPipe,
  ) {}

  ngOnInit(): void {
    if (this.enabledRole && !this.allowed.transform(this.enabledRole)) {
      this.modelControl.disable();
    }

    let promise: Promise<void>;

    if (this.modelName) {
      promise = this.setInitialModelByName(this.modelName);
    } else if (this.modelId) {
      promise = this.setInitialModelById(this.modelId);
    } else {
      promise = Promise.resolve();
    }

    promise.then(() => {
      if (this.modelControl.enabled) {
        this.filterModels();
      }
    });
  }

  public displayFn(model: DeviceModel): string {
    return model?.name ?? '';
  }

  public modelSelected(event: MatAutocompleteSelectedEvent): void {
    this.modelNameChange.emit(event.option.value?.name ?? null);
    this.modelIdChange.emit(event.option.value?.id ?? null);
  }

  private setInitialModelByName(modelName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (modelName) {
        this.deviceModelService.getModels(0, 100, modelName).subscribe({
          next: (response) => {
            if (response.content.length > 0) {
              this.model = response.content[0];
              this.modelControl.setValue(this.model);
            }
            resolve();
          },
          error: () => {
            resolve();
          },
        });
      } else {
        resolve();
      }
    });
  }

  private setInitialModelById(modelId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (modelId) {
        this.deviceModelService.getDeviceModel(modelId).subscribe({
          next: (response) => {
            this.model = response;
            this.modelControl.setValue(this.model);
            resolve();
          },
          error: () => {
            resolve();
          },
        });
      } else {
        resolve();
      }
    });
  }

  private filterModels(): void {
    this.filteredModels = this.modelControl.valueChanges.pipe(
      startWith(this.model ?? ''),
      debounceTime(500),
      switchMap((value) => {
        let name = '';
        if (value) {
          name = typeof value === 'string' ? value : value?.name;
        }
        this.loading = true;

        return this.deviceModelService.getModelsByNameAndCategory(name, this.categoryName, this.pageable, this.deviceId).pipe(
          map((value) => value.content),
          finalize(() => (this.loading = false)),
        );
      }),
      catchError((error) => {
        return of([]); // Return an empty array in case of error
      }),
    );

    // Set error on FormControl if entered value doesn't exist in model list
    this.filteredModels.subscribe(() => {
      const enteredValueTostring = this.modelControl.value?.name ?? this.modelControl.value.toString();
      const selectedValue = this.modelControl.value?.name;

      if (enteredValueTostring && !selectedValue) {
        this.modelControl.setErrors({ notFound: true });
        this.modelError.emit(true);
      } else if (!enteredValueTostring && !selectedValue && !this.modelControl.pristine) {
        this.modelControl.setErrors(null);
        this.modelError.emit(false);
        this.modelNameChange.emit(null);
        this.modelIdChange.emit(null);
      } else {
        this.modelControl.setErrors(null);
        this.modelError.emit(false);
      }
    });
  }
}
