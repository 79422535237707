import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nullish',
    standalone: false
})
export class NullishPipe implements PipeTransform {
  transform<T>(value: T, nullishValue = '---'): T | string {
    return value ?? nullishValue;
  }
}
