import { Pipe, PipeTransform } from '@angular/core';
import languageCodeListJson from '../../shared/repositories/countrydata/ISO-639-1-language.json';

@Pipe({
    name: 'languageCode',
    standalone: false
})
export class LanguageCodePipe implements PipeTransform {
  transform(value: string): string {
    languageCodeListJson.forEach((language) => {
      if (language.code === value.toLowerCase()) {
        value = language.name;
      }
    });
    return value;
  }
}
